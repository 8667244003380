import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { mergeMap, filter, map } from 'rxjs/operators';
import { DistrictsService } from '../districts/districts.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  sharePicture = '';

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private districts: DistrictsService,
    @Inject(DOCUMENT) private dom,
  ) {
    districts.getSitePictures().subscribe((data) => {
      this.sharePicture = data.sitePictures[0].url;
      meta.addTag({ meta: 'twitter:image', content: this.sharePicture });
      meta.addTag({ name: 'og:image', content: this.sharePicture });
    });

    meta.addTag({ name: 'twitter:card', content: 'summary' });
    // meta.addTag({name: 'twitter:site', content: ''}); // @handle to be associated with the content
    meta.addTag({ name: 'twitter:title', content: 'Know Your Hoods' });
    meta.addTag({ name: 'twitter:text:title', content: 'Know Your Hoods' });
    // meta.addTag({meta: 'twitter:description', content: ''});

    meta.addTag({ name: 'og:type', content: 'article' });
    meta.addTag({ name: 'og:title', content: '' });
    meta.addTag({ name: 'og:description', content: '' });

    meta.addTag({ name: 'description', content: '' });
    meta.addTag({ name: 'author', content: '' });
    meta.addTag({ name: 'keywords', content: '' });
  }

  updateMetaInfo(
    content = '',
    author = '',
    category = '',
    image = this.sharePicture,
    type = 'article',
  ) {
    this.meta.updateTag({ name: 'twitter:description', content: content });
    this.meta.updateTag({ name: 'description', content: content });
    this.meta.updateTag({ name: 'og:description', content: content });

    this.meta.updateTag({ name: 'author', content: author });

    this.meta.updateTag({ name: 'keywords', content: category });

    if (image !== '') {
      this.meta.updateTag({ name: 'og:image', content: image });
      this.meta.updateTag({ name: 'twitter:image', content: image });
    }

    this.meta.updateTag({ name: 'og:type', content: type });
  }

  updateFBMeta(title = '', description = '', image = '', type = '') {
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:description', content: description });
    this.meta.updateTag({ name: 'og:image', content: image });
    this.meta.updateTag({ name: 'og:type', content: type });
  }

  updateTitle(title?: string) {
    if (!title) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data),
        )
        .subscribe((event) => {
          this.titleService.setTitle(event['title'] + ' | Hoods.fi');
        });
    } else {
      this.titleService.setTitle(title + ' | Hoods.fi');
      this.meta.updateTag({ name: 'og:title', content: title });
      this.meta.updateTag({ name: 'twitter:title', content: title });
      this.meta.updateTag({ name: 'twitter:text:title', content: title });
    }
  }

  updateImage(url: string) {
    this.meta.updateTag({ name: 'og:image', content: url });
    this.meta.updateTag({ name: 'twitter:image', content: url });
  }

  updateDescription(content: string) {
    this.meta.updateTag({ name: 'twitter:description', content: content });
    this.meta.updateTag({ name: 'description', content: content });
    this.meta.updateTag({ name: 'og:description', content: content });
  }

  updateType(type: string) {
    this.meta.updateTag({ name: 'og:type', content: type });
  }

  updateKeywords(keywords: string[]) {
    this.meta.updateTag({ name: 'keywords', content: keywords.join(', ') });
  }

  updateCanonical(url: string) {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
    this.meta.updateTag({ name: 'og:url', content: url });
  }
}
