import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { Event } from '../../../services/events/event.model';
import { EventService, Filter } from '../../../services/events/event.service';
import { PaginationOptions } from '../../paginator/paginator.component';
import { waitForMs } from '../../../utils/time';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { EventFilterComponent } from '../event-filter/event-filter.component';
import { FabService } from '../../fab/fab.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss'],
})
export class EventViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() hoodId: number;
  @Input() municipalityId: number;

  private requester: 'hood' | 'municipality';
  @Input() hood: any;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public events: Event[] = [];
  public totalEvents: number;
  public pagination: PaginationOptions = {
    pageIndex: 1,
    pageSize: 20,
  };

  public loading = false;
  public currentLanguage = 'fi';

  private scrollPosition: [number, number];

  constructor(
    private eventService: EventService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public fabService: FabService,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
  ) {
    this.currentLanguage = this.translate.currentLang;

    this.translate.onLangChange.subscribe((event) => {
      this.currentLanguage = this.translate.currentLang;
    });
  }

  ngOnInit() {
    this.requester = this.hoodId ? 'hood' : 'municipality';
    this.getEvents();
    this.updateFilters();
    this.getLoadingStatus();
    this.setFabButtons();
  }

  ngAfterViewInit(): void {
    // TODO: Find a better way to scroll to cards
    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .pipe(delay(500))
      .subscribe((params) => {
        if (params.item) {
          return;
        }
        if (this.scrollPosition && this.scrollPosition.length > 0) {
          this.viewportScroller.scrollToPosition(this.scrollPosition);
        }
      });
  }

  ngOnDestroy() {
    // Clear filter options for mobile when navigating away
    this.clearFilterOptions();
    this.fabService.setShowFeedbackBubbleState(true);
    this.fabService.setShowUpState(true);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  scrollToEventCard() {
    this.scrollPosition = this.viewportScroller.getScrollPosition();
  }

  clearFilterOptions() {
    const emptyFilter: Filter = {
      formControlAudience: [],
      formControlContent: [],
      formControlType: [],
      formControlSuper: false,
      formControlStart: '',
      formControlEnd: '',
    };
    this.eventService.setFilters(emptyFilter);
  }

  setFabButtons() {
    this.fabService.setShowFeedbackBubbleState(false);
    this.fabService.setShowUpState(false);
  }

  getEvents() {
    const id = this.hoodId ? this.hoodId : this.municipalityId;
    this.eventService
      .getEvents(id, this.requester, {
        page: this.pagination.pageIndex,
        limit: this.pagination.pageSize,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.events = response.data;
        this.totalEvents = response.meta.filter_count;
        this.eventService.setLoadingStatus(false);
      });
  }

  onPagination(pagination: PaginationOptions) {
    this.pagination = pagination;
    this.getEvents();
  }

  updateFilters() {
    this.eventService.currentFilters.pipe(takeUntil(this.destroyed$)).subscribe(async (filters) => {
      await waitForMs(3000);
      if (filters) {
        this.getEvents();
      }
    });
  }

  getLoadingStatus() {
    this.eventService.loadingStatus
      .pipe(takeUntil(this.destroyed$))
      .subscribe((loading) => (this.loading = loading));
  }

  turnPage(dir: string) {
    if (dir === 'next') {
      this.pagination.pageIndex++;
      this.getEvents();
    }
    if (dir === 'prev') {
      if (this.pagination.pageIndex > 1) {
        this.pagination.pageIndex--;
        this.getEvents();
      }
    }
  }

  openFilters() {
    const dialogRef = this.dialog.open(EventFilterComponent, {
      maxWidth: '100vw',
      height: '100%',
      width: '100%',
      panelClass: 'event-modal',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const filters = this.eventService.getFilters();
        if (JSON.stringify(data) !== JSON.stringify(filters)) {
          this.eventService.setFilters(data);
        }
      }
    });
  }
}
