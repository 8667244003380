import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDrawerContent } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster.layersupport';
import { Options } from 'ng5-slider';
import { of, ReplaySubject } from 'rxjs';
// Partials
import { MapComponent } from '../../../components/map/map.component';
import { SearchCurtainComponent } from '../../../components/search-curtain/search-curtain.component';
import { DistrictsService } from '../../../services/districts/districts.service';
// Service providers
import { takeUntil } from 'rxjs/operators';
import { HoodsService } from '../../../services/hoods/hoods.service';
import { MapDataService } from '../../../services/mapdata/mapdata.service';
import { MetaService } from '../../../services/meta/meta.service';
import { MunicipalitiesService } from '../../../services/municipalities/municipalities.service';
import { Property } from '../../../services/properties/property.model';
import { RoutingState } from '../../../services/routing/routingState';
import { TextsService } from '../../../services/texts/texts.service';
import { FabService } from '../../../components/fab/fab.service';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: ' translateY(0)',
          visibility: 'visible',
        }),
      ),
      state(
        'closed',
        style({
          transform: 'translateY(-200vh)',
          visibility: 'hidden',
        }),
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class SearchResultsComponent implements AfterViewInit, OnInit, OnDestroy {
  constructor(
    private hoods_api: HoodsService,
    private districts_api: DistrictsService,
    private mapdata: MapDataService,
    private _router: Router,
    private route: ActivatedRoute,
    public strings: TextsService,
    public translate: TranslateService,
    private router: Router,
    private routingState: RoutingState,
    private municipalities: MunicipalitiesService,
    private meta: MetaService,
    private municipalityService: MunicipalitiesService,
    public fabService: FabService,
    private renderer: Renderer2,
  ) {
    if (this.route.snapshot.params.lang) {
      this.mapdata.saveLanguage(this.route.snapshot.params.lang);
      this.searchRoute = '/' + this.currentLang + '/search';
    }

    // this.texts = strings.parseStrings();

    this.currentLang = this.translate.currentLang;

    this.translate.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      this.currentLang = this.translate.currentLang;
      this.searchRoute = '/' + this.currentLang + '/search';
    });

    if (this.mapdata.getPreference('sorting') !== undefined) {
      if (this.mapdata.getPreference('sorting') !== '') {
        this.sorting = this.mapdata.getPreference('sorting');
      }
    }

    this.municipalities
      .getMunicipalities()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.municipalityList = data.municipalities;
      });

    this.municipalities
      .getPictures()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.municipalityPictures = data.municipalityPictures;
      });

    this.hoods_api
      .getHoodsFromCountry()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.allHoods = [];
        data.hoods.forEach((hood) => {
          this.allHoods.push(hood);
        });
      });
  }

  get sortedResults() {
    if (this.results !== undefined) {
      const f = this.mapdata.sortingField;
      let d = 1;

      return this.results.sort((a, b) => {
        if (this.mapdata.sortingDirection === 'descending') {
          d = -1;
        }
        if (a[f] < b[f]) {
          return -1 * d;
        }
        if (a[f] > b[f]) {
          return 1 * d;
        }
        return 0;
      });
    } else {
      return [];
    }
  }
  @ViewChild(MapComponent)
  map: MapComponent;

  @ViewChild(SearchCurtainComponent)
  filter: SearchCurtainComponent;

  // @ViewChild(MatDrawerContainer) drawerContainer:ElementRef;
  // @ViewChildren('mat-drawer-container', {read: ElementRef}) el: QueryList<ElementRef>;

  @ViewChildren(MatDrawerContent, { read: ElementRef }) drawerContainer: QueryList<ElementRef>;

  @ViewChild('resultsContainer') resultsContainer: ElementRef;

  image;
  municipalitySlug;
  municipalityName;
  countResults = 0;
  loadingMore = false;
  isOpen = true;
  refresh = false;
  query = {
    buildings: [],
    services: [],
    nature: [],
    rent: [],
    sales: [],
    pricefilter: '',
    features: [],
    mode: 'a',
    lots: false,
    lotstypes: [],
    lotspricetypes: [],
    municipalities: [],
    address: '',
    addressWayOfTransport: 'pedestrian',
    addressTimeLimit: '5m',
  };
  differ: any;
  results = [];
  noresults = false;
  menu = {
    mode: 'side',
  };
  previousRoute;
  hoods = of(this.results);
  listControl = new FormControl();
  allHoods = [];
  municipalityList = [];
  municipalitiesCenters = [];
  // Todo two way data binding not working atm with the ng5-slider component
  filters = {
    showMenu: false,
    visibleFilters: {
      mapPoints: true,
      services: true,
      environment: true,
      price: true,
    },
    rent: {
      min: '0',
      max: '1500',
    },
    buy: {
      min: '0',
      max: '1000',
    },
    environment: {
      built: '20',
      nature: '100',
    },
  };
  minRent = this.filters.rent.min;
  maxRent = this.filters.rent.max;
  options: Options = {
    floor: 0,
    ceil: 1500,
  };
  selectedLot: Property = {} as Property;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  texts;
  currentLang = 'fi';
  activeSort = 1;
  sorting = {
    // 'page': 20, // Hoods per page
    // 'start': 0,
    sort: 'name',
    dir: 'asc',
  };
  searchRoute = '/search';

  municipalityPictures = [];

  hoodsList: string[];
  hoodFilter = '';

  toggleCurtain($event?) {
    if ($event) {
      this.isOpen = !$event;
    } else {
      this.isOpen = !this.isOpen;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allHoods.filter((hood) => hood.name.toLowerCase().includes(filterValue));
  }

  getSingleHood() {
    this.results = this._filter(this.listControl.value.toLowerCase());
  }

  getMunicipalityDetails(id: number) {
    return {
      name: { fi: 'Turku', en: 'Turku' },
    };
    // return this.municipalityList.filter((item) => { return item.id == id })[0];
  }

  getMunicipalityPicture(id: number) {
    const pictures = this.municipalityPictures
      .filter((item) => {
        return item.municipalityId === id && item.type === 3;
      })
      .sort((a, b) => {
        return a.priority - b.priority;
      });
    if (pictures[0] !== undefined) {
      return pictures[0].url;
    }
    return '';
  }

  updateFilter() {
    this.hoodsList = this._filter(this.hoodFilter);
  }

  ngOnInit() {
    // References to listControl are commented out in template
    // let searchDelay;
    // this.listControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
    //   clearTimeout(searchDelay);
    //   searchDelay = setTimeout(() => {
    //     this.hoodFilter = value;
    //     this.updateFilter();
    //   }, 1000);
    // });

    // this.previousRoute = this.routingState.getHistory();

    this.results = this.mapdata.hoods;

    if (this.results !== undefined && this.results.length > 0) {
      this.isOpen = false;
    }

    // Map city servicepoints
    // this.map.mapServicePoints('city');

    // BUG: Cannot get points when view has changed to another and back

    this.map.mapReady.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.map.map !== null && this.map.map !== undefined) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams && urlParams.has('bbox')) {
          const bboxStr = urlParams.get('bbox');
          const [southWestLng, southWestLat, northEastLng, northEastLat] = bboxStr
            .split(',')
            .map((s) => parseFloat(s));
          const bounds = L.latLngBounds([northEastLat, northEastLng], [southWestLat, southWestLng]);
          this.map.map.fitBounds(bounds);
        } else if (this.mapdata.hoods !== undefined) {
          this.map.map.invalidateSize();
          this.zoomToHoods();
        }
      }
    });

    if (this.filter.fetchingActive === false) {
      this.filter.fetchingActive = true;
      this.mapdata.hoodResults.pipe(takeUntil(this.destroyed$)).subscribe((results) => {
        this.results = results;
        this.filter.fetchingActive = false;
      });
    }

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.map.mapReady.pipe(takeUntil(this.destroyed$)).subscribe(() => {
          this.zoomToHoods();
        });
      }
    });

    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params.scroll) {
        setTimeout(() => {
          const id = params.scroll;
          const el: Element = document.getElementById('card-' + id);
          el.scrollIntoView();
          this.zoomToHoods();
        }, 1000);
      }

      this.previousRoute = this.routingState.getHistory();

      if (this.checkSearchRefresh()) {
        // TODO: Check if user just hit back button and disable refresh

        this.filter.fetchingActive = true;
        this.refresh = true;

        this.toggleCurtain(true);

        // Clear results
        this.results = [];
        this.mapdata.clearHoods();
        this.mapdata.hoods = [];

        // Get search params
        this.query = {
          buildings: params.buildings,
          services: params.services,
          nature: params.nature,
          rent: params.rent,
          sales: params.sales,
          pricefilter: params.pricefilter,
          features: params.features,
          mode: params.mode,
          lots: params.lots,
          lotstypes: params.lotstypes,
          lotspricetypes: params.lotspricetypes,
          municipalities: params.municipalities,
          address: params.address,
          addressTimeLimit: params.addressTimeLimit,
          addressWayOfTransport: params.addressWayOfTransport,
        };

        if (params.nature) {
          this.filter.nature.min = params.nature[0];
          this.filter.nature.max = params.nature[1];

          this.filter.buildings.min = params.buildings[0];
          this.filter.buildings.max = params.buildings[1];

          this.filter.rentprice.min = params.rent[0];
          this.filter.rentprice.max = params.rent[1];

          this.filter.salesprice.min = params.sales[0];
          this.filter.salesprice.max = params.sales[1];

          this.filter.priceFilter = params.pricefilter;
        }
        if (params.lots !== undefined) {
          if (params.lots === 'true') {
            this.mapdata.savePreference('showLots', true);
            this.map.mapReady.pipe(takeUntil(this.destroyed$)).subscribe(() => {
              if (this.map.map !== undefined) {
                this.map.map.addLayer(this.map.properties);
              }
            });
          } else {
            this.mapdata.savePreference('showLots', false);
          }
        }
        // Get hoods
        this.getHoods(true);
      }
    });

    this.handleBodyScrollBar('add');
    this.setFabButtons();
    // this.setDistanceFromTurkuText();
  }

  // BUG: Quick fix for https://app.shortcut.com/cyf-digital-services-oy/story/16086/hoods-alueen-keskipistettä-ei-saa-määriteltyä
  showDistanceFromTurkuText(hood) {
    const hoodsToHide = ['sippola', 'anjala', 'inkeroinen'];
    const show = hoodsToHide.includes(hood.slug);
    if (show) {
      return false;
    }
    return true;
  }

  // Check if search changed and return true if it did,
  // otherwise return false
  // TODO: Make a more elegant solution
  checkSearchRefresh() {
    const routingHistory = this.routingState.getHistory();
    const routingLength = routingHistory.length;

    const router = this.router.url.split('?');

    let prevSearch;

    if (routingLength > 1) {
      prevSearch = routingHistory[routingHistory.length - 1]['params'];
    } else {
      prevSearch = '';
    }

    if (router.length < 1) {
      router[1] = '';
    }

    if (router[1] !== prevSearch) {
      return true;
    } else {
      return false;
    }
  }

  refreshResults() {
    this.countResults = 0;
    // this.sorting.page = 10;
    this.mapdata.clearHoods();

    this._router.navigate(['/results']);
    this.getHoods(true);
  }

  trackResults(i, hood) {
    return hood.name;
  }

  setSorting(field, direction) {
    this.results = [];

    this.sorting.sort = field;
    this.sorting.dir = direction;
    this.getHoods(true);

    this.mapdata.savePreference('sorting', this.sorting);
  }

  getRegionLink(id) {
    const regions = {
      1: 'turku',
      2: 'kaarina',
    };
    return regions[id];
  }

  getHoodLink(id) {
    const hoods = {
      16: 'hirvensalo-kakskerta',
      7: 'keskusta',
      8: 'kupittaa',
      11: 'nummi',
      13: 'paattinen',
      17: 'pansio',
      29: 'port-arthur',
      28: 'paaskyvuori',
      26: 'runosmaki',
      27: 'varissuo',
      30: 'pohjola',
    };

    return hoods[id];
  }

  showMore() {
    if (this.countResults > this.results.length) {
      // this.sorting.page = this.sorting.page + 20;
      this.loadingMore = true;
      this.getHoods(true);
    }
  }

  getHoods(refresh = false) {
    if (
      (refresh === true && this.filter.fetchingActive === false) ||
      (!this.mapdata.hoodsResultsExist() && this.filter.fetchingActive === false)
    ) {
      this.filter.fetchingActive = true;
      this.noresults = false;
      this.countResults = 0;

      this.hoods_api
        .searchHoods({
          services: this.query.services,
          nature: this.query.nature,
          buildings: this.query.buildings,
          rent: this.query.rent,
          sales: this.query.sales,
          pricefilter: this.query.pricefilter,
          mode: this.query.mode,
          features: this.query.features,
          sorting: this.sorting,
          lots: this.query.lots,
          lotstypes: this.query.lotstypes,
          lotspricetypes: this.query.lotspricetypes,
          municipalities: this.query.municipalities,
          address: this.query.address,
          addressTimeLimit: this.query.addressTimeLimit,
          addressWayOfTransport: this.query.addressWayOfTransport,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(async (data) => {
          this.mapdata.clearHoods();

          this.countResults = data.count;
          this.loadingMore = false;

          if (data.hoods.length === 0) {
            this.mapdata.hoods = ['no results'];
            this.noresults = true;
          } else {
            const municipalityData = await this.municipalityService.getMunicipalities().toPromise();

            data.hoods.forEach((hood, i) => {
              this.noresults = false;

              if (hood !== undefined) {
                if (hood.area !== null) {
                  const municipality = municipalityData.municipalities.find(
                    (m) => m.id === hood.municipalityId,
                  );
                  hood.color = municipality ? municipality.color : undefined;

                  /* if(hood.municipalityId == 1){
                  hood.color = hsl(250, 100, 50).hex();
                }
                else{
                  hood.color = hsl(0, 100, 50).hex();
                }  */

                  const hoodJSON = hood.area.geometry;

                  hood.options = {
                    style: {
                      color: hood.color,
                      fillColor: hood.color,
                      fillOpacity: 0.05,
                      weight: 2,
                      opacity: 0.8,
                    },
                  };

                  hood.object = {
                    type: 'FeatureCollection',
                    features: [
                      {
                        type: 'Feature',
                        properties: {
                          name: hood.name,
                        },
                        geometry: hoodJSON,
                      },
                    ],
                  };

                  this.mapdata.addHood(hood);
                }
              }
            });

            this.mapdata.refreshHoods();
            this.zoomToHoods();
          }

          this.filter.fetchingActive = false;
          // this.toggleCurtain(true);

          setTimeout(() => {
            this.refreshMap();
            this.zoomToHoods();
          }, 2000);
        });
    }
  }

  zoomToHoods() {
    if (this.map.hoods !== undefined) {
      this.map.bounds = this.map.hoods.getBounds();
      if (this.map.bounds.isValid()) this.map.map.fitBounds(this.map.bounds);
    }
  }

  showDistrict(id) {
    this.districts_api
      .getDistrict(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.map.addLayer(data.district.area);
      });
  }

  refreshMap() {
    this.map.map.invalidateSize();
  }

  // Calculate distances between two points
  getDistance(origin) {
    const destination = [22.2661993038323, 60.4519743];
    if (origin == null) {
      return 0;
    }
    origin = origin.geometry;
    // return distance in meters
    const lon1 = this.toRadian(origin.coordinates[1]);
    const lat1 = this.toRadian(origin.coordinates[0]);
    const lon2 = this.toRadian(destination[1]);
    const lat2 = this.toRadian(destination[0]);

    const deltaLat = lat2 - lat1;
    const deltaLon = lon2 - lon1;

    const a =
      Math.pow(Math.sin(deltaLat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    const c = 2 * Math.asin(Math.sqrt(a));
    const EARTH_RADIUS = 6371;
    return ((c * EARTH_RADIUS * 1000) / 1000).toFixed(0);
  }

  toRadian(degree) {
    return (degree * Math.PI) / 180;
  }

  ngAfterViewInit() {
    this.mapdata.clearLocations();

    this.municipalityService
      .getMunicipalitiesCenters()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.municipalitiesCenters = data.municipalities;
        this.municipalitiesCenters.forEach((center) => {
          if (center.center) {
            this.mapdata.addLocation(center.center);
          }
        });
      });

    this.map.updateMap();

    // Update page meta
    this.translate
      .stream(['META.TITLE.SEARCH', 'META.DESCRIPTION.SEARCH', 'META.KEYWORDS.SEARCH'])

      .pipe(takeUntil(this.destroyed$))
      .subscribe((text: string) => {
        this.meta.updateTitle(text['META.TITLE.SEARCH']);
        this.meta.updateDescription(text['META.DESCRIPTION.SEARCH']);
        this.meta.updateCanonical('https://hoods.fi/' + this.currentLang + '/search');
        this.meta.updateType('article');
        this.meta.updateKeywords(text['META.KEYWORDS.SEARCH'].split(';'));
      });
  }

  resultToText(object, type) {
    if (type === 'buildings' && object !== undefined) {
      const stats = [
        {
          string: 'AREA.HOUSETYPES.SMALLHOUSES',
          qty: object.ra_pt_as,
        },
        {
          string: 'AREA.HOUSETYPES.FLATS',
          qty: object.ra_kt_as,
        },
        {
          string: 'AREA.HOUSETYPES.SUMMERHOUSES',
          qty: object.ra_ke,
        },
      ];
      stats.sort((a, b) => {
        if (a.qty === b.qty) {
          return 0;
        }
        return a.qty < b.qty ? 1 : -1;
      });
      return [stats[0].string, stats[1].string];
    }

    if (type === 'nature' && object !== undefined) {
      const p = object * 100;
      if (p < 3) {
        return 'AREA.NATURE.NO_PARKS';
      }
      if (3 < p && p < 20) {
        return 'AREA.NATURE.SOME_PARKS';
      }
      if (20 < p && p < 40) {
        return 'AREA.NATURE.AVERAGE_PARKS';
      }
      if (40 < p && p < 100) {
        return 'AREA.NATURE.MANY_PARKS';
      }
    }
  }

  ngOnDestroy() {
    this.fabService.setShowUpState(true);
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.mapdata.clearSearchPoint();
    this.handleBodyScrollBar('remove');
  }

  /**
   * Fixes issue where the main body's scroll bar interferes
   * with curtain component's scrolling. Adds or removes the
   * global class to the body element.
   * @param action Set value to either add or remove
   */
  handleBodyScrollBar(action: string) {
    if (action === 'add') {
      this.renderer.addClass(document.body, 'hide-body-scroll');
    }
    if (action === 'remove') {
      this.renderer.removeClass(document.body, 'hide-body-scroll');
    }
  }

  centerMap(hood) {
    const object = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: hood.area.geometry,
        },
      ],
    };

    const geojson = L.geoJSON(object as any);

    // get area bounds and zoom map to fit whole area
    const bounds = geojson.getBounds();
    this.map.map.fitBounds(bounds);

    // Todo: open tooltip on click
    this.map.hoodAreas.forEach((area, index) => {
      const layers = area.getLayers();
      layers.forEach((layer) => {
        if (index === hood.id) {
          layer.openTooltip();
        } else {
          layer.closeTooltip();
        }
      });
    });
  }

  getHoodFeatures(features, cat) {
    if (features !== undefined) {
      return features
        .filter((f) => f.featureCategoryId === cat)
        .filter((f) => f.priority > 0)
        .sort((a, b) => {
          return a.priority - b.priority;
        });
    } else {
      return [];
    }
  }

  // arranging distances by priority isn't possible yet (priority data not provided)
  arrangeHoodDistances(distances) {
    if (distances !== undefined) {
      return distances
        .filter((d) => d.priority > 0)
        .sort((a, b) => {
          return a.priority - b.priority;
        });
    }
  }

  scrollTop() {
    const resultsElem = document.getElementById('results-element');
    const searchElem = document.getElementById('search-element');

    window.scroll({
      top: 0,
      left: 0,
    });

    // this.resultsContainer.nativeElement.scroll({
    //   top: 0,
    //   left: 0
    // });

    resultsElem.scroll({
      top: 0,
      left: 0,
    });

    searchElem.scroll({
      top: 0,
      left: 0,
    });

    this.drawerContainer.first.nativeElement.scroll({
      top: 0,
      left: 0,
    });
  }

  fixMapPosition() {
    // Fixes map position after closing lot dialog
    this.map.map.panBy([0, 150]);
  }

  isMobileDevice() {
    return /Mobi/i.test(navigator.userAgent);
  }

  setFabButtons() {
    this.fabService.setShowUpState(false);
  }
}
