import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translation',
  pure: false,
})
export class TranslationFilter implements PipeTransform {
  transform(content: any, args?: any): any {
    let cleanup = content.replace(/(\r\n|\n|\r)/gm, '');
    let regex = new RegExp('{{lang:' + args + '}}(.*){{/lang:' + args + '}}');
    let split = cleanup.split(regex);
    return split[1];
  }
}
