export class Event {
  id: string;
  status: string;
  date_created: string;
  date_updated: string;
  event_status: string;
  type_id: string;
  data_source: string;
  publisher: string;
  created_time: string;
  last_modified_time: string;
  date_published: string;
  start_time: string;
  end_time: string;
  is_virtualevent: boolean;
  is_owner: boolean;
  audience_min_age: number;
  audience_max_age: number;
  maximum_attendee_capacity: number;
  minimum_attendee_capacity: number;
  enrolment_start_time: string;
  enrolment_end_time: string;
  enrolment_url: string;
  super_event_type: string;
  sub_event_type: string;
  deleted: boolean;
  virtualevent_url: string;
  replaced_by: string;
  info_url: Translation;
  '@id': string;
  '@context': string;
  '@type': string;
  is_free: boolean;
  point: Point;
  external_links: TranslationExternalLink[];
  offers: Offers[];
  sub_events: Uri[];
  images: Image[];
  in_language: Uri[];
  custom_data: any;
  provider_contact_info: any;
  short_description: Translation;
  description: Translation;
  location_extra_info: Translation;
  name: Translation;
  provider: Translation;
  keywords: Uri[];
  location: Location;
  super_event_id: string;
  location_uri: string;
  super_event: string;
  super_event_name: Translation;
  audience: Category[];
  areas: Area[];
  linked_events_category: Category[];
}

export class Point {
  type: string;
  coordinates: number[];
}

export class Translation {
  fi: string;
  en?: string;
  sv?: string;
}

export class TranslationExternalLink {
  language: string;
  link: string;
  name: string;
}

export class Offers {
  payment_methods: [];
  is_free: boolean;
  info_url: Translation;
  description: Translation;
  price: Translation;
}

export class Uri {
  '@id': string;
}

export class Image {
  id: number;
  license: string;
  created_time: string;
  last_modified_time: string;
  url: string;
  cropping: string;
  photographer_name: string;
  data_source: string;
  publisher: string;
  name: Translation;
  alt_text: Translation;
  '@id': string;
  '@context': string;
  '@type': string;
}

export class Location {
  address_region: Translation;
  address_country: Translation;
  street_address: Translation;
  postal_code: string;
  address_locality: Translation;
  name: Translation;
  info_url: Translation;
}

export class Area {
  id: number;
  linked_events_id: string;
  area_id: string;
}

export class Category {
  id: number;
  linked_events_id: string;
  linked_events_category_id: string;
}

export class LinkedEventsCategory {
  id: string;
  status: string;
  sort: number | null;
  date_created: string;
  date_updated: string;
  name: string;
  category_group: CategoryGroup;
}

export enum CategoryGroup {
  CONTENT = 'turku:topic_content',
  TYPE = 'turku:topic_type',
  AUDIENCE = 'turku:audience',
}
