import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MunicipalitiesService,
  Municipality,
} from '../../../services/municipalities/municipalities.service';
import { delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, ReplaySubject, of } from 'rxjs';
import { Location } from '../../../services/locations/locations.model';
import { ServicesFilterService } from '../../../services/services-filter/services-filter.service';
import { SimpleMapComponent } from '../../simple-map/simple-map.component';
import { FabService } from '../../fab/fab.service';
import { MatDialog, MatSlideToggleChange } from '@angular/material';
import { AreaProperties, FeatureCollection, ServicesService } from '../services.service';
import { SimpleMapService } from '../../simple-map/simple-map.service';
import { ServicesFilterMobileComponent } from '../services-filter-mobile/services-filter-mobile.component';

export interface PaginationOptions {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
})
export class ServicesListComponent implements OnInit, OnDestroy {
  @ViewChild(SimpleMapComponent) simpleMapComponent: SimpleMapComponent;

  @Input() municipality: Municipality;
  @Input() hood;

  public listServices$: Observable<Location[]> = this.servicesFilterService.updateServices$.pipe(
    tap(() => (this.isLoading = true)),
    delay(1000),
    switchMap(() => this.getListServices()),
  );

  public mapServices$: Observable<FeatureCollection> =
    this.servicesFilterService.updateServices$.pipe(
      delay(1000),
      switchMap((updateMap) => {
        if (!updateMap) {
          return of(null);
        }
        return this.getMapServices();
      }),
    );

  public total: number;
  private pageSize = 10;
  public paginationOptionsRef: PaginationOptions = {
    pageIndex: 1,
    pageSize: this.pageSize,
  };
  private tabIndex = 0;
  private tabQueryMap = {
    0: false,
    1: true,
  };
  public isLoading = false;
  public isFabOpenState = {
    map: false,
    filter: false,
  };
  public hideFabButtons = false;
  public pageNumberCount = 7;
  public showMunicipalityServices = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private municipalitiesService: MunicipalitiesService,
    private servicesFilterService: ServicesFilterService,
    private fabService: FabService,
    public dialog: MatDialog,
    private simpleMapService: SimpleMapService,
    private servicesService: ServicesService,
  ) {
    this.fabService.setShowFeedbackBubbleState(false);
    this.fabService.setShowUpState(false);
    if (this.servicesService.isMobile) {
      this.pageNumberCount = 5;
    }
  }

  ngOnInit() {
    this.setSelectedArea();
    this.mapServices$.pipe(takeUntil(this.destroyed$)).subscribe();
    this.itemDetailsStateChanged();
    this.servicesService.isMobileMapOpen$.pipe().subscribe((state: boolean) => {
      this.isFabOpenState.map = state;
    });
    this.initShowMunicipalityToggleState();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setSelectedArea(tabSelected?: number) {
    if (this.hood) {
      const hoodsArea: AreaProperties = {
        id: this.hood.id,
        color: this.municipality.color,
        area: this.hood.area,
      };
      this.simpleMapService.setHoodArea(hoodsArea);
    }
    let selectedArea = this.hood ? this.hood : this.municipality;
    if (tabSelected) {
      selectedArea = this.hood;
      if (tabSelected === 1) {
        selectedArea = this.municipality;
      }
    }
    const mapped: AreaProperties = {
      id: selectedArea.id,
      color: selectedArea.color ? selectedArea.color : this.municipality.color,
      area: selectedArea.area,
    };
    this.servicesService.setAreaProperties(mapped);
  }

  initShowMunicipalityToggleState() {
    const state = this.servicesFilterService.filters.showMunicipalityServices;
    this.showMunicipalityServices = state;
    this.servicesService.setShowMunicipalityServices(state);
  }

  getListServices() {
    if (!this.municipality.id) {
      return;
    }

    const pagination = {
      offset: (this.paginationOptionsRef.pageIndex - 1) * this.paginationOptionsRef.pageSize,
      limit: this.paginationOptionsRef.pageSize,
    };

    const hoodId = this.hood ? this.hood.id : undefined;
    const hoodListQuery = {
      intersects: this.servicesFilterService.filters.showMunicipalityServices ? null : hoodId,
      reverseIntersect: this.tabQueryMap[this.tabIndex],
    };

    return this.municipalitiesService
      .getMunicipalityLocations(
        this.municipality.id,
        this.servicesFilterService.filters,
        false,
        pagination,
        hoodListQuery,
      )
      .pipe(
        map((result) => {
          this.total = result.count;
          this.isLoading = false;
          return result.data;
        }),
      );
  }

  getMapServices(): Observable<FeatureCollection> {
    const hoodId = this.hood ? this.hood.id : undefined;
    const hoodListQuery = {
      intersects: this.servicesFilterService.filters.showMunicipalityServices ? null : hoodId,
      reverseIntersect: this.tabQueryMap[this.tabIndex],
    };
    return this.municipalitiesService
      .getMunicipalityLocations(
        this.municipality.id,
        this.servicesFilterService.filters,
        true,
        undefined,
        hoodListQuery,
      )
      .pipe(
        map((data) => {
          const featureCollection = this.servicesService.mapLocationToFeatureCollection(data.data);
          this.simpleMapService.loadMapServices(featureCollection);
          return featureCollection;
        }),
      );
  }

  paginationOptions(event: PaginationOptions) {
    this.paginationOptionsRef = {
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    };
    this.servicesFilterService.refreshServices(false);
  }

  tabChanged(event) {
    this.paginationOptionsRef = {
      pageIndex: 1,
      pageSize: this.pageSize,
    };
    this.tabIndex = event.index;
    this.servicesFilterService.refreshServices(true);
    this.setSelectedArea(this.tabIndex);
  }

  openMapModal() {
    if (this.isFabOpenState.map) {
      this.dialog.closeAll();
    } else {
      this.dialog.open(SimpleMapComponent, {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        panelClass: 'simple-map-dialog',
      });
    }
    this.isFabOpenState.map = !this.isFabOpenState.map;
  }

  openFilterModal() {
    const filterDialogRef = this.dialog.open(ServicesFilterMobileComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'simple-map-dialog',
    });
    filterDialogRef.afterOpened().subscribe(() => {
      this.isFabOpenState.filter = !this.isFabOpenState.filter;
    });
    filterDialogRef.afterClosed().subscribe((data) => {
      this.isFabOpenState.filter = !this.isFabOpenState.filter;
    });
  }

  itemDetailsStateChanged() {
    this.servicesService.isItemDetailsOpen$.subscribe((data) => {
      this.hideFabButtons = data;
    });
  }

  slideToggleChanged(event: MatSlideToggleChange) {
    if (!this.hood.id) {
      return;
    }
    this.showMunicipalityServices = event.checked;
    this.servicesService.setShowMunicipalityServices(event.checked);
    if (event.checked) {
      this.tabIndex = 1;
    } else {
      this.tabIndex = 0;
    }
    this.servicesFilterService.refreshServices(true);
    this.paginationOptionsRef.pageIndex = 1;
    this.setSelectedArea(this.tabIndex);
  }
}
