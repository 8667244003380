import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface HasTranslation {
  translations: {
    languages_code: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translate: TranslateService) {}

  /**
   * Translates the content of a Directus translation object. Defaults to Finnish when
   * selecting Swedish.
   * @param object An object with translations
   * @returns Translations of the currently selected application language
   */
  getCorrectTranslation<T extends HasTranslation>(object: T): T['translations'][0] {
    let defaultTranslationObject;
    let translationObject = object.translations.find((t) => {
      if (t.languages_code.split('-')[0].toLocaleLowerCase().includes('fi')) {
        defaultTranslationObject = t;
      }
      return (
        t.languages_code &&
        t.languages_code
          .split('-')[0]
          .toLowerCase()
          .includes(this.translate.currentLang.toLowerCase())
      );
    });

    if (!translationObject) {
      translationObject = object.translations[0];
      if (defaultTranslationObject && this.translate.currentLang.toLowerCase() === 'sv') {
        translationObject = defaultTranslationObject;
      }
    }
    return translationObject;
  }
}
