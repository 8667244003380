import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { MapDataService, ServiceType } from '../../services/mapdata/mapdata.service';

@Component({
  selector: 'service-points',
  templateUrl: './service-points.component.html',
  styleUrls: ['./service-points.component.scss'],
})
export class ServicePointsSelector implements OnDestroy {
  @Output() refreshPoints = new EventEmitter<any>();

  @Output() close = new EventEmitter<any>();

  lotsShown: boolean = false;
  hoodsShown: boolean = false;
  showHoodsSetting = false;

  @Output() toggleLots = new EventEmitter<boolean>();
  @Output() toggleHoods = new EventEmitter<boolean>();

  serviceCategories;
  serviceTypes: ServiceType[];
  serviceFilter = 'city';

  serviceIcons = {
    1: 'fire-alt',
    3: 'hand-holding-heart',
    15: 'briefcase-medical',
    17: 'briefcase',
    18: 'recycle',
    11: 'child',
    12: 'school',
    13: 'graduation-cap',
    16: 'baby',
    6: 'bus',
    19: 'gas-pump',
    2: 'store',
    4: 'piggy-bank',
    5: 'h-square',
    20: 'prescription-bottle-alt',
    7: 'guitar',
    8: 'place-of-worship',
    9: 'running',
    10: 'chess-rook',
    14: 'book-reader',
    21: 'spring',
    // 22: 'event'
    24: 'tree',
    48: 'utensils',
    49: 'coffee',
    50: 'glass-martini',
    51: 'carrot',
    52: 'sync',
    53: 'store-alt',
    54: 'car',
    55: 'mobile-alt',
    56: 'spa',
    57: 'home',
    58: 'user-friends',
    59: 'tshirt',
    60: 'smile',
    61: 'bed',
    62: 'landmark',
  };

  private isMapPage = false;
  private destroyed$ = new ReplaySubject(1);

  constructor(private mapdata: MapDataService, private readonly location: Location) {
    this.mapdata.servicePointTypes$.pipe(takeUntil(this.destroyed$)).subscribe(async (types) => {
      // Check if same already
      if (JSON.stringify(types) === JSON.stringify(this.serviceTypes)) {
        return;
      }
      this.serviceTypes = types;
      const cats = await this.mapdata.getServicePointCats();
      this.serviceCategories = cats;
      this.updateSelected();
    });

    const url = window.location.href;
    this.isMapPage = url.includes('/map');
    const isBigCityMap = !!url.match(/map\/(\w+)/);
    this.showHoodsSetting = url.includes('city') || isBigCityMap;
    if (url.includes('search')) this.toggleHoodsLayer(true);

    this.lotsShown = !!this.mapdata.getPreference('showLots');
    this.hoodsShown = !!this.mapdata.getPreference('showHoods');
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  // SERVICE POINT FUNCTIONS

  serviceTypesByCat(id) {
    return this.serviceTypes.filter((type) => type.locationCategoryId == id);
  }

  getSelectedServices() {
    // Return an array of selected service type IDs
    return this.serviceTypes.filter((type) => type.selected);
  }

  getSelectedServicesByCat(id) {
    // Return an array of selected service type IDs
    return this.serviceTypes.filter((type) => type.selected && type.locationCategoryId == id);
  }

  updateSelected() {
    this.serviceCategories.forEach((category, index) => {
      let selected = this.getSelectedServicesByCat(category.id);
      if (selected.length > 0) this.serviceCategories[index].selected = true;
      else this.serviceCategories[index].selected = false;
    });
  }

  getSelectedServiceIDs() {
    // Return an array of selected service type IDs
    return this.serviceTypes.filter((type) => type.selected).map((type) => type.id);
  }

  saveSelectedServices() {
    this.mapdata.saveServicePointTypes(this.serviceTypes);
    this.filterRefresh(this.serviceFilter);
    this.updateSelected();

    if (this.isMapPage) {
      // Edit url to have selected types
      const selectedIds = this.serviceTypes.filter((x) => x.selected).map((x) => x.id);

      let params = new HttpParams();
      params = params.append('categories', selectedIds.join(','));

      this.location.replaceState(location.pathname, params.toString());
    }
  }

  clearSelectedServices() {
    let uncheck = [];
    this.serviceTypes.forEach((type) => {
      type.selected = false;
      uncheck.push(type);
    });
    this.serviceTypes = uncheck;
    this.saveSelectedServices();

    // TODO: There is a visual bug when clearing the services.
    // Find a method to refresh the template instead of using scrollBy().
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  }

  filterRefresh(filter) {
    this.mapdata.savePreference('area', this.serviceFilter);
    // this.map.mapServicePoints(filter);
    this.refreshPoints.next(filter);
  }

  closeDialog() {
    this.close.next(true);
  }

  toggleLotsLayer() {
    this.toggleLots.next(this.lotsShown);
    this.mapdata.savePreference('showLots', this.lotsShown);
  }
  toggleHoodsLayer(show?: boolean) {
    if (show !== undefined) {
      this.hoodsShown = show;
    }
    this.toggleHoods.next(this.hoodsShown);
    this.mapdata.savePreference('showHoods', this.hoodsShown);
  }
}
