import { LatLng, LatLngBounds } from 'leaflet';

export function PadNorth(bounds: LatLngBounds, bufferRatio: number): LatLngBounds {
  var sw = bounds.getSouthWest(),
    ne = bounds.getNorthEast(),
    heightBuffer = Math.abs(sw.lat - ne.lat) * bufferRatio;

  return new LatLngBounds(new LatLng(sw.lat, sw.lng), new LatLng(ne.lat + heightBuffer, ne.lng));
}

export function bboxString(map: L.Map): string {
  const bounds = map.getBounds();
  const southWest = bounds.getSouthWest();
  const northEast = bounds.getNorthEast();
  const bbox = `${southWest.lng},${southWest.lat},${northEast.lng},${northEast.lat}`;
  return bbox;
}
