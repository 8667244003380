import { Component, OnInit } from '@angular/core';
import {
  SelectedServiceFilters,
  ServicesFilterService,
} from '../../../services/services-filter/services-filter.service';
import { TranslationService } from '../../../services/translation/translation.service';

@Component({
  selector: 'app-services-filter',
  templateUrl: './services-filter.component.html',
  styleUrls: ['./services-filter.component.scss'],
})
export class ServicesFilterComponent implements OnInit {
  public parentCategories;
  public childCategories;
  public dataSources;
  public sourceBadge = 0;
  public categoryBadge = 0;
  // Hidden until services list includes services from other sources
  public showSourceFilter = false;
  public parentCategoryId = this.servicesFilterService.ASSOCIATION_CATEGORY.id;

  constructor(
    private servicesFilterService: ServicesFilterService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.getCategories();
  }

  async getCategories() {
    const { parents, childs, sources } = await this.servicesFilterService.getCategories();
    this.parentCategories = parents;
    this.childCategories = childs;
    this.parentCategories.forEach((parent) => {
      const hasChildren = this.childCategories.filter(
        (child) => parent.id === child.locationCategoryId,
      );
      if (hasChildren) {
        parent.children = hasChildren;
      }
    });
    this.dataSources = sources;
    this.getSourceBadgeCount();
    this.getCategoryBadgeCount();
    this.getParentChildBadgeCount();
  }

  translateText(data) {
    return this.translationService.getCorrectTranslation(data);
  }

  saveSelectedFilters() {
    const selected = this.getUserSelection();
    this.servicesFilterService.setSelectedServicesFilters(selected);

    this.getCategoryBadgeCount();
    this.getParentChildBadgeCount();
    this.getSourceBadgeCount();
  }

  getUserSelection(): SelectedServiceFilters {
    const filteredSources = this.dataSources.filter((source) => source.selected);
    const selectedSources = filteredSources.map((source) => source.code);

    const filteredCategories = this.childCategories.filter((child) => child.selected);
    const selectedCategories = filteredCategories.map((child) => child.id);

    const selected: SelectedServiceFilters = {
      sources: selectedSources,
      categories: selectedCategories,
    };
    return selected;
  }

  resetUserSelection() {
    this.childCategories.forEach((child) => {
      child.selected = false;
    });
    this.dataSources.forEach((source) => {
      // TODO: Temporarily set data source 12 as default filter. Remove this default
      // when integrating other service types.
      if (source.code !== 12) {
        source.selected = false;
      }
    });
    this.saveSelectedFilters();
  }

  getParentChildBadgeCount() {
    this.parentCategories.forEach((parent) => {
      const selectedChilds = parent.children.filter((child) => child.selected);
      parent.selectedChildCount = selectedChilds.length;
    });
  }

  getSourceBadgeCount() {
    this.sourceBadge = this.servicesFilterService.getSourceBadgeCount(this.dataSources);
  }

  getCategoryBadgeCount() {
    this.categoryBadge = this.childCategories.filter((child) => child.selected).length;
  }

  onChildCheckboxClick(event) {
    event.stopPropagation();
  }

  trackChildItem(index, item) {
    return item.id;
  }
}
