import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event } from '../../../services/events/event.model';
import { getDateRange } from '../../../utils/getFormattedDate';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { EventItemComponent } from '../event-item/event-item.component';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @Input() public data: Event;
  @Output() isEventCardOpen = new EventEmitter<boolean>();

  // public placeholderImage = 'assets/images/hood-placeholder.jpeg';
  public currentLang = 'fi';

  public name = '';
  public imageUrl = '';
  public imageAltText = '';
  public date = '';

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.currentLang = this.translate.currentLang;

    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = this.translate.currentLang;
    });
  }

  ngOnInit() {
    if (this.data) {
      this.date = this.getEventDate();
      this.imageUrl = this.data.images.length > 0 ? this.data.images[0].url : '';
      this.imageAltText =
        this.data.images.length > 0 &&
        this.data.images[0].alt_text &&
        this.data.images[0].alt_text[this.currentLang]
          ? this.data.images[0].alt_text[this.currentLang]
          : '';
      this.name = this.data.name[this.currentLang] ? this.data.name[this.currentLang] : '';
    }
  }

  getEventDate(): string {
    return getDateRange(this.data.start_time, this.data.end_time);
  }

  openEventItem() {
    this.router.navigate([], { queryParams: { item: this.data.id } });
    this.isEventCardOpen.emit(true);
    // const dialogRef = this.dialog.open(EventItemComponent, {
    //   maxWidth: '100vw',
    //   height: '100%',
    //   width: '100%',
    //   panelClass: 'event-modal',
    //   data: { id: this.data.id },
    // });
  }
}
