import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TokenStorageService } from '../../services/registration/token-storage.service';
import { HoodsService } from '../../services/hoods/hoods.service';
import { UserService } from '../../services/registration/user.service';
import { MunicipalitiesService } from '../../services/municipalities/municipalities.service';

@Component({
  selector: 'residence-card',
  templateUrl: './residence-card.component.html',
  styleUrls: ['./residence-card.component.scss'],
})
export class ResidenceCardComponent implements OnInit {
  results = [];
  userHoodSlug;
  municipalityPictures = [];

  currentLang = 'fi';

  constructor(
    private hoodsService: HoodsService,
    private tokenStorage: TokenStorageService,
    private userService: UserService,
    public translate: TranslateService,
    private municipalities: MunicipalitiesService,
  ) {
    this.currentLang = this.translate.currentLang;

    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = this.translate.currentLang;
    });
  }

  ngOnInit() {
    this.getUserHoodSlug();
    this.getMunicipalityPictures();
  }

  getUserHood() {
    const userId: number = this.tokenStorage.getCurrentUser().id;
    return new Promise((resolve) => {
      this.userService.getUserDetailsById(userId).subscribe((data) => {
        const userHood = data.details.hood;
        resolve(userHood);
      });
    });
  }

  async getHoodDetails(id) {
    return new Promise((resolve) => {
      this.hoodsService.getHood(id).subscribe((data) => {
        console.log(data);
        const dataHood = data['hood'];
        this.results.push(dataHood);
        const userHoodSlug = dataHood.slug;
        resolve(userHoodSlug);
      });
    });
  }

  async getUserHoodSlug() {
    const hoodId = await this.getUserHood();
    const hoodSlug = await this.getHoodDetails(hoodId);
    this.userHoodSlug = hoodSlug;
    return new Promise((resolve) => {
      resolve(hoodSlug);
    });
  }

  getMunicipalityPictures() {
    this.municipalities.getPictures().subscribe((data) => {
      this.municipalityPictures = data.municipalityPictures;
    });
  }

  getMunicipalityPicture(id: number) {
    let pictures = this.municipalityPictures.filter((item) => {
      return item.municipalityId == id && item.type == 0;
    });
    if (pictures[0] !== undefined) {
      return pictures[0].url;
    }
    return '';
  }
}
