import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounce, debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'ng5-slider';
import { Observable, ReplaySubject } from 'rxjs';
import { FeaturesService } from '../../services/features/features.service';
import { GeocodingService } from '../../services/geocoding/geocoding.service';
import { LocationsService } from '../../services/locations/locations.service';
import { MapDataService } from '../../services/mapdata/mapdata.service';
import { MapComponent } from '../map/map.component';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Municipality } from '../../services/municipalities/municipalities.service';
import { sortMunicipalitiesWithHoodsAlphabetically } from '../../utils/getLocale';

@Component({
  selector: 'search-curtain',
  templateUrl: './search-curtain.component.html',
  styleUrls: ['./search-curtain.component.scss'],
})
export class SearchCurtainComponent implements OnInit, OnDestroy {
  @ViewChild(MapComponent)
  map: MapComponent;

  @Output() closeCurtain = new EventEmitter<boolean>();

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  faMapMarkerAlt = faMapMarkerAlt;

  savedMapPoints = false;
  showNature = false;

  usePoints = false;

  useLots = false;

  lotsTypes = {
    other: false,
    housing: false,
    flats: false,
    business: false,
    freetime: false,
  };
  municipalitiesFilter: number[] = [];

  lotsPriceTypes = {
    sales: false,
    rent: false,
  };

  // We'll use this to check for duplicate queries for now
  fetchingActive = false;

  tooltipOptions = {
    theme: 'light',
    placement: 'right',
    'hide-delay': 100,
    'animation-duration': 50,
    'max-width': '500px',
  };

  serviceIcons = {
    1: 'fire-alt',
    3: 'hand-holding-heart',
    15: 'briefcase-medical',
    17: 'briefcase',
    18: 'recycle',
    11: 'child',
    12: 'school',
    13: 'graduation-cap',
    16: 'baby',
    6: 'bus',
    19: 'gas-pump',
    2: 'store',
    4: 'piggy-bank',
    5: 'h-square',
    20: 'prescription-bottle-alt',
    7: 'guitar',
    8: 'place-of-worship',
    9: 'running',
    10: 'chess-rook',
    14: 'book-reader',
    24: 'tree',
  };

  filters = {
    buildings: {
      min: 0,
      max: 3,
      high: 3,
    },
    nature: {
      min: 0,
      max: 10,
      high: 10,
    },
    priceFilter: 'sales',
    salesprice: {
      min: 0,
      max: 2,
      high: 2,
    },
    rentprice: {
      min: 0,
      max: 2,
      high: 2,
    },
    mode: 'a',
    features: [],
    usepoints: false,
    lots: false,
    lotsTypes: {
      other: false,
      housing: false,
      flats: false,
      business: false,
      freetime: false,
    },
    lotsPriceType: {
      sales: false,
      rent: false,
    },
    municipalitiesFilter: [],
    address: '',
    addressWayOfTransport: 'pedestrian',
    addressTimeLimit: '5m',
  };

  priceFilter = this.filters.priceFilter;

  savedLocations = [];

  region = this.mapdata.region;

  currentLang = 'fi';

  options: Options = {
    floor: 0,
    ceil: 100,
  };

  buildings = this.filters.buildings;
  buildings_options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 0, legend: 'Maaseutumainen' },
      { value: 1, legend: 'Haja-asutusalue' },
      { value: 2, legend: 'Taajama' },
      { value: 3, legend: 'Kaupunkimainen' },
    ],
  };

  nature = this.filters.nature;
  nature_options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 0, legend: 'Ei viheralueita' },
      { value: 1 },
      { value: 2 },
      { value: 3, legend: 'Hieman viheralueita' },
      { value: 4 },
      { value: 5 },
      { value: 6, legend: 'Melko paljon viheralueita' },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10, legend: 'Metsäisä' },
    ],
  };

  salesprice = this.filters.salesprice;
  rentprice = this.filters.rentprice;
  price_options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 0, legend: '€' },
      { value: 0.5 },
      { value: 1, legend: '€€€' },
      { value: 1.5 },
      { value: 2, legend: '€€€€€' },
    ],
  };

  addressControl = new FormControl();
  addressPrediction: string[] = [];
  municipalityControl = new FormControl();
  chosenMunicipalities: Municipality[] = [];

  address = '';
  addressPrediction$ = new Observable<string[]>();
  addressWayOfTransport: 'bicycle' | 'car' | 'public' | 'pedestrian' = 'car';
  addressTimeLimit: string = '5m';

  distance_options = {
    min: 0,
    max: 10,
  };

  hoodFeatures = this.filters.features;
  featureFilterMode = this.filters.mode;

  texts;

  featureCategories;

  serviceCategories;
  serviceTypes;

  municipalities: Municipality[];

  searchRoute = '/search';
  showTooltipBox = false;

  constructor(
    public locations: LocationsService,
    public geocode: GeocodingService,
    public features: FeaturesService,
    private mapdata: MapDataService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    this.currentLang = this.translate.currentLang;

    if (this.route.snapshot.params['lang']) {
      this.mapdata.saveLanguage(this.route.snapshot.params['lang']);
      this.searchRoute = '/' + this.currentLang + '/search';
    }

    let keys = [
      'FILTER.PRICE.LESS',
      'FILTER.PRICE.AVERAGE',
      'FILTER.PRICE.MORE',
      'FILTER.DENSITY.COUNTRYSIDE',
      'FILTER.DENSITY.DISPERSED',
      'FILTER.DENSITY.URBAN',
      'FILTER.DENSITY.DOWNTOWN',
    ];

    this.translate
      .stream(keys)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((translations) => {
        if (translations[keys[0]] !== keys[0]) {
          this.price_options.stepsArray[0].legend = translations['FILTER.PRICE.LESS'];
          this.price_options.stepsArray[2].legend = translations['FILTER.PRICE.AVERAGE'];
          this.price_options.stepsArray[4].legend = translations['FILTER.PRICE.MORE'];

          this.buildings_options.stepsArray[0].legend = translations['FILTER.DENSITY.COUNTRYSIDE'];
          this.buildings_options.stepsArray[1].legend = translations['FILTER.DENSITY.DISPERSED'];
          this.buildings_options.stepsArray[2].legend = translations['FILTER.DENSITY.URBAN'];
          this.buildings_options.stepsArray[3].legend = translations['FILTER.DENSITY.DOWNTOWN'];

          // Changed slider options change detection workaround,
          // see: https://angular-slider.github.io/ng5-slider/demos#dynamic-options-slider
          this.buildings_options = Object.assign({}, this.buildings_options);
          this.nature_options = Object.assign({}, this.nature_options);
          this.price_options = Object.assign({}, this.price_options);
        }
      });

    features
      .getFeatures()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((features) => {
        const filtered = features.features.filter((feature) => feature.featureCategoryId !== 3);
        const groupedFeatures = filtered.reduce((acc, curr, i) => {
          const group = acc.find((g) => g.featureCategoryId === curr.featureCategoryId);

          let translationKey = 'FILTER.AREA.TITLE';

          if (group) {
            group.features.push(curr);
          } else {
            acc.push({
              featureCategoryId: curr.featureCategoryId,
              featureCategoryName:
                curr.featureCategoryId === 1
                  ? translationKey
                  : translationKey + curr.featureCategoryId,
              features: [curr],
            });
          }
          return acc;
        }, []);

        this.featureCategories = groupedFeatures;
      });

    this.translate.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      this.currentLang = this.translate.currentLang;

      this.municipalities = sortMunicipalitiesWithHoodsAlphabetically(
        this.municipalities,
        this.currentLang,
      );
      this.searchRoute = '/' + this.currentLang + '/search';
    });

    /* this.mapdata.getServicePointCats().then((cats) => {
      this.serviceCategories = cats;
    });

    this.mapdata.getServicePointTypes().then((types) => {
      this.serviceTypes = types;
    }); */

    this.mapdata.getMunicipalities().then((municipalities) => {
      this.municipalities = sortMunicipalitiesWithHoodsAlphabetically(
        municipalities,
        this.currentLang,
      );

      if (this.municipalitiesFilter && this.municipalitiesFilter.length > 0) {
        this.chosenMunicipalities = this.municipalitiesFilter
          .map((mId) => this.municipalities.find((m) => m.id === mId))
          .filter((x) => x);
      }
    });

    this.addressPrediction$ = this.addressControl.valueChanges.pipe(
      debounceTime(200),
      filter((value) => typeof value === 'string' && value.length > 1),
      switchMap((value: string) => this.geocode.getAutocompleteFromAzureMaps(value)),
    );
  }

  municipalitiesWithHoods(): Municipality[] {
    if (!this.municipalities) return [];

    return this.municipalities.filter((m) => m.hasHoods);
  }

  compareMunicipality(a: Municipality | undefined, b: Municipality | undefined): boolean {
    if (!a || !b) return false;
    return a.id === b.id;
  }

  useLotsFilter(): boolean {
    if (this.lotsTypes !== undefined && this.lotsPriceTypes !== undefined) {
      if (
        this.lotsTypes['other'] ||
        this.lotsTypes['flats'] ||
        this.lotsTypes['housing'] ||
        this.lotsTypes['business'] ||
        this.lotsTypes['freetime'] ||
        this.lotsPriceTypes['sales'] ||
        this.lotsPriceTypes['rent']
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  lotTypes() {
    if (
      !this.lotsTypes['other'] &&
      !this.lotsTypes['housing'] &&
      !this.lotsTypes['flats'] &&
      !this.lotsTypes['freetime'] &&
      !this.lotsTypes['business']
    ) {
      return null;
    } else {
      let types = [];

      if (this.lotsTypes['housing']) {
        types.push(1);
      }
      if (this.lotsTypes['business']) {
        types.push(3);
      }
      if (this.lotsTypes['freetime']) {
        types.push(4);
      }

      if (this.lotsTypes['other'] || this.lotsTypes['flats']) {
        types.push(0, 2);
      }

      return types;
    }
  }

  lotPriceTypes() {
    if (!this.lotsPriceTypes['sales'] && !this.lotsPriceTypes['rent']) {
      return null;
    } else {
      let types = [];
      if (this.lotsPriceTypes['sales']) {
        types.push('sales');
      }
      if (this.lotsPriceTypes['rent']) {
        types.push('rent');
      }
      return types;
    }
  }

  updateSelectedFeatures(event, id) {
    this.hoodFeatures[id] = event.target.checked;
  }

  public getSelectedFeatures(cat?: number) {
    let features = [];
    if (!this.featureCategories) {
      return;
    }
    const current = this.featureCategories.filter((c) => c.featureCategoryId === cat)[0];

    if (cat !== undefined) {
      if (
        this.featureCategories &&
        Array.isArray(this.featureCategories) &&
        Array.isArray(current.features)
      ) {
        current.features.forEach((item, index) => {
          if (this.hoodFeatures[item.id] !== undefined && this.hoodFeatures[item.id] == true) {
            features.push(index);
          }
        });
      }
    } else {
      if (Array.isArray(this.hoodFeatures)) {
        this.hoodFeatures.forEach((item, index) => {
          if (item !== undefined && item == true) {
            features.push(index);
          }
        });
      }
    }
    return features;
  }

  // TODO: This should be triggered by the event emitted by the map component somehow
  onMapLoaded() {
    this.mapdata.locations.forEach((location) => {
      this.getLocation(location.address).then((loc) => {
        location.location = <any>loc;
      });
    });
  }

  getLocation(address) {
    return new Promise((resolve) => {
      this.geocode
        .getLocationByAddress(address)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          resolve({
            lat: data[0].lat,
            lon: data[0].lon,
          });
        });
    });
  }

  syncChosenMunicipalities() {
    this.municipalitiesFilter = this.chosenMunicipalities.map((x) => x.id);
  }

  deleteLocation(index) {
    this.mapdata.removeLocation(index);
  }

  clearFilters() {
    //console.log(this.filters);

    this.filters.lots = false;

    this.filters.lotsTypes.housing = false;
    this.filters.lotsTypes.flats = false;
    this.filters.lotsTypes.business = false;
    this.filters.lotsTypes.freetime = false;
    this.filters.lotsTypes.other = false;
    this.lotsTypes = this.filters.lotsTypes;

    this.filters.mode = 'a';
    this.featureFilterMode = this.filters.mode;

    this.filters.lotsPriceType.rent = false;
    this.filters.lotsPriceType.sales = false;
    this.lotsPriceTypes = this.filters.lotsPriceType;

    this.filters.buildings.min = 0;
    this.filters.buildings.max = 3;
    this.buildings = this.filters.buildings;

    this.filters.nature.min = 0;
    this.filters.nature.max = 10;
    this.nature = this.filters.nature;

    this.filters.salesprice.min = 0;
    this.filters.salesprice.max = 2;
    this.salesprice = this.filters.salesprice;

    this.filters.rentprice.min = 0;
    this.filters.rentprice.max = 2;
    this.rentprice = this.filters.rentprice;

    this.filters.usepoints = false;
    this.filters.address = '';
    this.filters.addressTimeLimit = '5m';
    this.filters.addressWayOfTransport = 'pedestrian';
    this.address = '';
    this.addressTimeLimit = '5m';
    this.addressWayOfTransport = 'car';

    this.hoodFeatures = [];
    this.municipalitiesFilter = [];
    this.chosenMunicipalities = [];

    // this.clearSelectedServices();
  }

  ngOnDestroy() {
    // On destroy save filter to localstore
    this.saveFilters();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit() {
    // Digitransit's autocomplete - currently we are using azure maps
    // this.addressControl.valueChanges
    //   .pipe(
    //     debounceTime(1000),
    //     filter((value) => typeof value === 'string' && value.length > 0),
    //     switchMap((value: string) => this.geocode.getAutocomplete(value)),
    //     takeUntil(this.destroyed$),
    //   )
    //   .subscribe((results) => {
    //     // console.log(prediction);
    //     this.addressPrediction = results;
    //   });
  }

  ngAfterContentInit() {
    // Load filters from session storage
    let filters = this.mapdata.loadFilters();
    if (filters !== undefined) {
      this.filters = filters;
      this.priceFilter = this.filters.priceFilter;
      this.rentprice = this.filters.rentprice;
      this.salesprice = this.filters.salesprice;
      this.nature = this.filters.nature;
      this.buildings = this.filters.buildings;
      this.featureFilterMode = this.filters.mode;
      this.hoodFeatures = this.filters.features;
      this.usePoints = this.filters.usepoints;
      this.address = this.filters.address;
      this.addressTimeLimit = (this.filters.addressTimeLimit as any) || '5m';
      this.addressWayOfTransport = (this.filters.addressWayOfTransport as any) || 'car';
      this.useLots = this.filters.lots;
      this.lotsTypes = this.filters.lotsTypes;
      this.municipalitiesFilter = this.filters.municipalitiesFilter;
      this.lotsPriceTypes = this.filters.lotsPriceType;
    }
    if (
      this.municipalities &&
      this.municipalities.length > 0 &&
      this.municipalitiesFilter &&
      this.municipalitiesFilter.length > 0
    ) {
      this.chosenMunicipalities = this.municipalitiesFilter
        .map((mId) => this.municipalities.find((m) => m.id === mId))
        .filter((x) => x);
    }

    // console.log(this.map);
  }

  getPrefix(icon) {
    if (findIconDefinition({ prefix: 'fab', iconName: icon }) != undefined) {
      return 'fab';
    } else {
      return 'fas';
    }
  }

  // SERVICE POINT FUNCTIONS

  serviceTypesByCat(id) {
    if (this.serviceTypes !== undefined) {
      return this.serviceTypes.filter((type) => type.locationCategoryId == id);
    } else {
      return [];
    }
  }

  getSelectedServices() {
    if (this.serviceTypes !== undefined) {
      // Return an array of selected service type IDs
      return this.serviceTypes.filter((type) => type.selected);
    } else {
      return [];
    }
  }

  clearSelectedServices() {
    let uncheck = [];
    this.serviceTypes.forEach((type) => {
      type.selected = false;
      uncheck.push(type);
    });
    this.serviceTypes = uncheck;
    this.saveSelectedServices();
  }

  getSelectedServiceIDs() {
    if (this.serviceTypes !== undefined) {
      // Return an array of selected service type IDs
      return this.serviceTypes.filter((type) => type.selected).map((type) => type.id);
    } else {
      return [];
    }
  }

  saveSelectedServices() {
    // this.mapdata.saveServicePointTypes(this.serviceTypes);
    this.mapdata.filterRefreshed.next('city');
  }

  saveFilters() {
    this.filters = {
      buildings: {
        min: this.buildings.min,
        max: this.buildings.max,
        high: this.filters.buildings.high,
      },
      nature: {
        min: this.nature.min,
        max: this.nature.max,
        high: this.filters.nature.high,
      },
      priceFilter: this.priceFilter,
      salesprice: {
        min: this.salesprice.min,
        max: this.salesprice.max,
        high: this.filters.salesprice.high,
      },
      rentprice: {
        min: this.rentprice.min,
        max: this.rentprice.max,
        high: this.filters.rentprice.high,
      },
      mode: this.featureFilterMode,
      features: this.hoodFeatures,
      usepoints: this.usePoints,
      address: this.address,
      addressTimeLimit: this.addressTimeLimit,
      addressWayOfTransport: this.addressWayOfTransport,
      lots: this.useLotsFilter(),
      lotsTypes: this.lotsTypes,
      lotsPriceType: this.lotsPriceTypes,
      municipalitiesFilter: this.municipalitiesFilter,
    };

    // console.log(this.filters);

    this.mapdata.saveFilters(this.filters);
  }

  closeFilters() {
    this.closeCurtain.emit(true);
  }
}
