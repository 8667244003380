import { Location } from '@angular/common';
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationExtras, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import { geoJSON, icon, latLng, Marker } from 'leaflet';
import 'leaflet.locatecontrol';
import 'leaflet.markercluster';
import 'leaflet.markercluster.layersupport';
import { ReplaySubject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LeafletMessageBoxService } from '../../services/leaflet-messagebox/leafletMessageBox.service';
import { LocationsService } from '../../services/locations/locations.service';
import { MapDataService, SimplifiedLocation } from '../../services/mapdata/mapdata.service';
import { MunicipalitiesService } from '../../services/municipalities/municipalities.service';
import { PropertyService } from '../../services/properties/properties.service';
import { Property, ReferenceIdPrefix } from '../../services/properties/property.model';
import { bboxString, PadNorth } from '../../utils/geo';
import { waitForMs } from '../../utils/time';
import { MapMarkerPopupComponent } from '../map-marker-popup/map-marker-popup.component';
import { ServicePointsSelector } from '../service-points/service-points.component';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconDefault = icon({
  iconRetinaUrl,
  iconUrl: 'assets/icons/hoods-marker.png',
  shadowUrl: 'assets/icons/hoods-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  entryComponents: [ServicePointsSelector],
})
export class MapComponent implements OnDestroy {
  @Input() initLots = true;
  @Input() initLotsOpenDialog = true;
  private initLotsDialogOpened = false;

  lotsDataRequest: Subscription;
  constructor(
    private mapdata: MapDataService,
    public location: Location,
    public router: Router,
    public translate: TranslateService,
    private resolver: ComponentFactoryResolver,
    private municipalitiesService: MunicipalitiesService,
    private propertyService: PropertyService,
    private injector: Injector,
    private appRef: ApplicationRef,
    private leafletMessageboxService: LeafletMessageBoxService,
    public dialog: MatDialog,
    private locationService: LocationsService,
    private ngZone: NgZone,
  ) {
    this.layersControl = {
      baseLayers: {
        maptiles: this.maptiles,
      },
      overlays: {
        markers: this.municipalityCenterMarkers,
        hoods: this.hoods,
        services: this.services,
        turku_border: this.region_border,
      },
    };

    this.mapdata.currentFilter.pipe(takeUntil(this.destroyed$)).subscribe((area) => {
      this.area = area;
      this.mapServicePoints(area, { removeExistingPoints: true });
    });

    this.mapdata.searchPoint.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.searchpoints.clearLayers();
      L.marker(L.latLng([data.lat, data.lon]), this.pointOfInterestMarkerOptions)
        .bindTooltip(data.label)
        .addTo(this.searchpoints)
        .openTooltip();
      if (data.distance > 0) {
        L.circle(L.latLng([data.lat, data.lon]), {
          color: 'red',
          fillColor: '#f03',
          fillOpacity: 0.5,
          radius: data.distance * 1000,
        }).addTo(this.searchpoints);
      }
    });
  }
  @Output() ready = new EventEmitter<boolean>();
  @Output() mapLoaded = new EventEmitter<boolean>();

  area: 'city' | 'hood'; // save latest area information
  title = 'map';
  options: L.MapOptions = {};
  showLayer = true;
  map: L.Map;
  mapZoom;
  mapCenter;
  showPropertiesControl: false;
  moveZoomTimeout: any;

  public mapReady: ReplaySubject<L.Map> = new ReplaySubject();

  serviceIcons = {
    0: 'shapes',
    1: 'fire-alt',
    3: 'hand-holding-heart',
    15: 'briefcase-medical',
    17: 'briefcase',
    18: 'recycle',
    11: 'child',
    12: 'school',
    13: 'graduation-cap',
    16: 'baby',
    6: 'bus',
    19: 'gas-pump',
    2: 'store',
    4: 'piggy-bank',
    5: 'h-square',
    20: 'prescription-bottle-alt',
    7: 'guitar',
    8: 'place-of-worship',
    9: 'running',
    10: 'chess-rook',
    14: 'book-reader',
    21: 'spring',
    // 22: 'event'
    24: 'tree',
    48: 'utensils',
    49: 'coffee',
    50: 'glass-martini',
    51: 'carrot',
    52: 'sync',
    53: 'store-alt',
    54: 'car',
    55: 'mobile-alt',
    56: 'spa',
    57: 'home',
    58: 'user-friends',
    59: 'tshirt',
    60: 'smile',
    61: 'bed',
    62: 'landmark',
    63: 'user-tie',
    64: 'house-user',
    65: 'user-friends',
    66: 'blind',
    67: 'child',
    68: 'photo-video',
    69: 'hand-holding-heart',
    70: 'globe-americas',
  };

  maptiles = L.tileLayer('https://a.tile.openstreetmap.org/{z}/{x}/{y}.png ', {
    maxZoom: 20,
    attribution: '...',
  });
  // maptiles = L.tileLayer('https://b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { maxZoom: 20, attribution: '...' });
  // alt_maptiles = L.tileLayer('https://cdn.digitransit.fi/map/v1/hsl-map/{z}/{x}/{y}.png', { maxZoom: 20, attribution: '...' });

  savedLocations = [];

  municipalityCenterMarkers = L.layerGroup();
  hoods = L.featureGroup();
  properties = L.featureGroup();
  private addedLots = new Set<number>();
  searchpoints = L.layerGroup();
  openLotDialogId: number | undefined;
  markercluster: any = L.markerClusterGroup;
  private addedServicePoints: number[] = [];
  services: L.MarkerClusterGroup = this.markercluster.layerSupport({
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    spiderLegPolylineOptions: { opacity: 0 },
    maxClusterRadius: function (zoom) {
      return zoom == 18 ? 1 : 80;
    },
    // disableClusteringAtZoom: 18,
    iconCreateFunction: function (cluster) {
      return L.divIcon({
        html: '<div class="cluster"><strong>' + cluster.getChildCount() + '</strong></div>',
      });
    },
  });
  region_border: L.LayerGroup = L.layerGroup();

  bounds;

  geojsonMarkerOptions = {
    icon: icon({
      iconSize: [32, 32],
      shadowSize: [32, 32],
      shadowAnchor: [0, 32],
      iconAnchor: [16, 32],
      iconUrl: 'assets/icons/pin-marker.png',
      shadowUrl: 'assets/icons/pin-shadow.png',
    }),
  };

  searchPointMarkerOptions = {
    icon: icon({
      iconSize: [32, 32],
      shadowSize: [32, 32],
      shadowAnchor: [0, 32],
      iconAnchor: [16, 32],
      iconUrl: 'assets/icons/pin-marker.png',
      shadowUrl: 'assets/icons/pin-shadow.png',
    }),
  };

  pointOfInterestMarkerOptions = {
    icon: icon({
      iconSize: [32, 32],
      shadowSize: [32, 32],
      shadowAnchor: [16, 32],
      iconAnchor: [16, 32],
      iconUrl: 'assets/icons/hoods-marker.png',
      shadowUrl: 'assets/icons/hoods-shadow.png',
    }),
  };

  // municipalityCenterMarkers are added dynamically on zoom level
  layers = [this.services, this.region_border, this.searchpoints];

  private lotMarkers: L.MarkerClusterGroup = (L.markerClusterGroup as any).layerSupport({
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    spiderLegPolylineOptions: { opacity: 0 },
    maxClusterRadius: function (zoom) {
      return zoom == 18 ? 1 : 80;
    },
    // disableClusteringAtZoom: 18,
    iconCreateFunction: function (cluster) {
      let bounds = cluster.getBounds();
      let isIdenticalPoint =
        JSON.stringify(bounds._northEast) === JSON.stringify(bounds._southWest);
      if (isIdenticalPoint) {
        return L.divIcon({
          html: '<div class="lot-cluster-text" style="font-size: 12px;">●●●</div>',
          className: 'lot-cluster',
        });
      }
      return L.divIcon({
        html: '<div class="lot-cluster-text">' + cluster.getChildCount() + '</div>',
        className: 'lot-cluster',
      });
    },
  });

  selectedLot: Property = {} as Property;

  layersControl;

  showServicePoints: boolean = true;
  showControls: boolean = true;

  hoodAreas: L.GeoJSON[] = [];

  resizeTimer;

  servicePointContainer = L.DomUtil.create('div', 'leaflet-layer-menu');
  servicePointComponent = this.resolver
    .resolveComponentFactory(ServicePointsSelector)
    .create(this.injector);
  private servicePoints: SimplifiedLocation[];

  pointMenuEvent = new EventEmitter<any>();
  pointMenuOpen = false;

  body = document.querySelector('body');
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  handleBBox = (event) => {
    if (this.moveZoomTimeout) {
      clearTimeout(this.moveZoomTimeout);
    }
    if (event.type === 'zoomend' && this.map) {
      const zoom = this.map.getZoom();
      const hasMarkerLayer = this.map.hasLayer(this.municipalityCenterMarkers);
      if (zoom > 13) {
        if (!hasMarkerLayer) {
          this.map.addLayer(this.municipalityCenterMarkers);
        }
      } else {
        if (hasMarkerLayer) {
          this.map.removeLayer(this.municipalityCenterMarkers);
        }
      }
    }
    this.moveZoomTimeout = setTimeout(() => {
      if (this.initLots) {
        this.loadLots();
      }
      this.mapServicePoints(this.area, { refetchPoints: true });
    }, 100);
  };

  showCard(id) {
    let el = document.querySelector('#' + 'card-' + id);
    if (!el) return;
    let container = document.getElementById('resultsContainer');
    let rect = el.getBoundingClientRect();
    if (screen.width < 769) {
      el.scrollIntoView({ block: 'center' });
    } else {
      el.scrollIntoView();
      window.scroll({
        top: 0,
        left: 0,
      });
    }
    // console.log(el.getBoundingClientRect().top);
  }
  toggleMap() {
    // rootpath must take into account language parameter in url
    let rootpath = this.location.path().split('/')[2];
    let largeMapPath = this.location.path().split('/')[1].replace(/\?.*/, '');
    if (
      largeMapPath !== 'map' &&
      rootpath !== 'map' &&
      rootpath !== 'kaarina' &&
      rootpath !== 'turku'
    ) {
      let target;
      const extraParams: NavigationExtras = {
        queryParams: {
          bbox: bboxString(this.map),
          lotId: this.selectedLot && this.selectedLot.id,
          returnUrl: encodeURIComponent(window.location.href),
        },
      };
      if (rootpath == 'hood') {
        target = this.mapdata.hood;
      }
      if (rootpath == 'city') {
        target = this.location.path().split('/')[3].replace(/\?.*/, '');
      } else {
        // reloading page causes search results to dissappear from large-map
        target = '';
      }
      this.ngZone.run(() => {
        this.router.navigate(['/', this.translate.currentLang, 'map', target], extraParams);
      });
      return;
    }

    // We are on big map page

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams && urlParams.has('returnUrl')) {
      let returnUrl = decodeURIComponent(urlParams.get('returnUrl'));
      const queryParams: Params = {};

      const returnUrlParams = new URLSearchParams(returnUrl.replace(/^.*\?/, ''));
      if (returnUrlParams) {
        returnUrlParams.forEach((v, k) => {
          queryParams[k] = encodeURIComponent(v);
        });
      }
      queryParams['bbox'] = bboxString(this.map);
      queryParams['lotId'] = this.selectedLot && this.selectedLot.id;
      const extraParams: NavigationExtras = {
        queryParams,
      };
      if (returnUrl.startsWith(window.location.origin)) {
        returnUrl = returnUrl.replace(window.location.origin, '').replace(/\?.*/, '');
        this.ngZone.run(() => {
          this.router.navigate([returnUrl], extraParams);
        });
        return;
      }
      // Fallback to other handling below
    }

    // Check if muni slug and return there
    const slugRegex = window.location.href.match(/map\/(\w+)/);
    if (slugRegex) {
      const queryParams: Params = {};
      queryParams['bbox'] = bboxString(this.map);
      const extraParams: NavigationExtras = {
        queryParams,
      };

      const muniSlug = slugRegex[1];
      const lang = this.translate.currentLang;
      this.ngZone.run(() => {
        this.router.navigate([`/${lang}/city/${muniSlug}`], extraParams);
      });
      return;
    }

    // Fallback to back in history
    this.location.back();
  }

  updateMap() {
    // Clear existing markers from the marker layer
    this.municipalityCenterMarkers.clearLayers();

    // Bind locations from filter component to map point layer
    let mapPoints = this.mapdata.locations.map((o) => {
      return {
        type: 'Feature',
        properties: {
          name: o.name[this.translate.currentLang],
        },
        geometry: {
          type: 'Point',
          coordinates: [o.location.lon, o.location.lat],
        },
      };
    });

    let featureCollection = {
      type: 'FeatureCollection',
      features: mapPoints,
    };

    let geojsonMarkerOptions = {
      icon: icon({
        iconSize: [32, 32],
        shadowSize: [32, 32],
        shadowAnchor: [16, 32],
        iconAnchor: [16, 32],
        iconUrl: 'assets/icons/hoods-marker.png',
        shadowUrl: 'assets/icons/hoods-shadow.png',
      }),
    };

    const municipalityCenterMarkers = L.geoJSON(<any>featureCollection, {
      pointToLayer: function (feature, latlng) {
        return L.marker(latlng, geojsonMarkerOptions).bindTooltip(feature.properties.name, {
          direction: 'top',
          offset: L.point(-2, -30),
        });
      },
    });
    this.municipalityCenterMarkers.addLayer(municipalityCenterMarkers);

    this.mapdata.hoodResults.pipe(takeUntil(this.destroyed$)).subscribe((hoods) => {
      if (hoods !== undefined) {
        this.hoods.clearLayers();

        // Show hoods on map
        hoods.forEach((hood) => {
          let style = hood.options.style;
          // console.log(hood.municipalityId);
          /* if (hood.municipalityId == 1) {
            style.color = hsl(250, 100, 50).hex();
            style.fillColor = hsl(250, 100, 50).hex();
            style.fillOpacity = 0.05;
          }
          else if(hood.municipalityId == 2) {
            style.color = hsl(0, 100, 50).hex();
            style.fillColor = hsl(0, 100, 50).hex();
            style.fillOpacity = 0.05;
          }
          else{
            style.color = hsl(hood.municipalityId * 110, 100, 50).hex();
            style.fillColor = 'transparent';
          } */

          let options = {
            style: style,
            onEachFeature: (feature, layer) => {
              layer.bindTooltip(feature.properties.name, { permanent: false, direction: 'center' });
            },
          };
          let hoodArea = L.geoJSON(hood.object, options).on('click', () => {
            this.showCard(hood.id);
          });
          this.hoodAreas[hood.id] = hoodArea;
          this.hoodAreas[hood.id].addTo(this.hoods);
        });

        this.properties.bringToFront();
      }
    });
  }

  ngOnInit() {
    this.ready.next(true);

    this.mapZoom = 8;
    this.mapCenter = latLng(60.447678, 22.264959);

    this.options = {
      layers: [this.maptiles],
      zoomControl: false,
      maxZoom: 18,
    };

    this.mapLoaded.emit(true);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.servicePointComponent.destroy();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(this.setPopUpPosition, 500);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.setPopUpPosition();
  }

  @HostListener('window:wheel', ['$event'])
  onWheel(event) {
    this.setPopUpPosition();
  }

  setPopUpPosition() {
    if (this.servicePointComponent !== undefined) {
      let boundingBox = this.servicePointContainer.getBoundingClientRect();
      this.servicePointComponent.location.nativeElement.setAttribute(
        'style',
        'top:' + (boundingBox.bottom + window.scrollY) + 'px;left:' + boundingBox.left + 'px;',
      );
    }
  }

  onMapReady(map: L.Map) {
    let showLots = this.mapdata.getPreference('showLots');
    let lotsLayer = this.properties;
    if (showLots) {
      map.addLayer(lotsLayer);
      this.servicePointComponent.instance.lotsShown = true;
    } else {
      map.removeLayer(lotsLayer);
      this.servicePointComponent.instance.lotsShown = false;
    }
    let showHoods = this.mapdata.getPreference('showHoods');
    let hoodsLayer = this.hoods;
    if (showHoods) {
      map.addLayer(hoodsLayer);
      this.servicePointComponent.instance.hoodsShown = true;
    } else {
      map.removeLayer(hoodsLayer);
      this.servicePointComponent.instance.hoodsShown = false;
    }
    this.mapdata
      .getPreferences()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((preferences) => {
        if (!preferences) return;

        if (preferences['showLots']) {
          map.addLayer(lotsLayer);
          this.servicePointComponent.instance.lotsShown = true;
        } else {
          map.removeLayer(lotsLayer);
          this.servicePointComponent.instance.lotsShown = false;
        }

        if (preferences['showHoods']) {
          map.addLayer(hoodsLayer);
          this.servicePointComponent.instance.hoodsShown = true;
        } else {
          map.removeLayer(hoodsLayer);
          this.servicePointComponent.instance.hoodsShown = false;
        }
      });

    if (this.showControls) {
      let customControl = L.Control.extend({
        options: {
          position: 'topright',
        },
        onAdd: (map) => {
          var container = L.DomUtil.create(
            'button',
            'leaflet-bar leaflet-control leaflet-control-maptoggle',
          );

          let expand = this.translate.instant('AC.BUTTON.EXPAND');
          let compress = this.translate.instant('AC.BUTTON.COMPRESS');

          let rootpath = this.location.path().split('/')[1];
          if (rootpath !== 'map') {
            container.innerHTML =
              '<i class="fas fa-expand-arrows-alt"></i><span class="sr-only">' + expand + '</span>';
          } else {
            container.innerHTML =
              '<i class="fas fa-compress-arrows-alt"></i><span class="sr-only">' +
              compress +
              '</span>';
          }

          container.style.width = '35px';
          container.style.height = '35px';
          container.style.cursor = 'pointer';

          container.onclick = () => {
            this.toggleMap();
          };
          return container;
        },
      });

      let servicePointControl = L.Control.extend({
        options: { position: 'topleft' },
        onAdd: (map) => {
          let container = this.servicePointContainer;

          let icon = '';

          /* this.servicePointComponent.refreshPoints.subscribe((data) => {

          });
          this.mapdata.getServicePointTypes().then(types => {
            let selected = types.filter((type) => { return type.selected });
            if(selected.length > 0){
              icon = '<i class="fas fa-check"></i>';
            }
          }); */
          let arrow = 'down';

          this.translate
            .stream('MAP.SHOW_SERVICES')
            .pipe(takeUntil(this.destroyed$))
            .subscribe((translate) => {
              if (translate == 'MAP.SHOW_SERVICES') {
                if (this.translate.currentLang == 'fi') {
                  translate = 'Näytä palvelut';
                } else {
                  translate = 'Show services';
                }
              }

              container.innerHTML =
                '<button class="toggle mat-flat-button">' +
                translate +
                icon +
                ' <i class="fa fa-chevron-' +
                arrow +
                '" style="pointer-events:none"></i></button>';

              this.pointMenuEvent.pipe(takeUntil(this.destroyed$)).subscribe(() => {
                if (this.pointMenuOpen) {
                  arrow = 'up';
                } else {
                  arrow = 'down';
                }
                container.innerHTML =
                  '<button class="toggle mat-flat-button">' +
                  translate +
                  icon +
                  ' <i class="fa fa-chevron-' +
                  arrow +
                  '" style="pointer-events:none"></i></button>';
              });
            });

          // container.style.width = '130px';
          container.style.cursor = 'pointer';

          container.onclick = ($e: any) => {
            // TODO: There is a bug when closing the component menu and opening it again.
            // Find a method to refresh the servicePointComponent instead of using scrollBy().
            window.scrollBy(0, 1);
            window.scrollBy(0, -1);

            if ($e.target.className.split(' ').indexOf('toggle') > -1) {
              this.openServiceFilter();
            }
          };
          return container;
        },
      });

      map.addControl(L.control.zoom({ position: 'topright' }));

      map.addControl(new customControl());

      map.addControl(new servicePointControl());

      // Add control for property toggle
      if (this.showPropertiesControl) {
        // this.addPropertiesControl();
      }

      let locateOptions = {
        position: 'topright',
        strings: {
          title: this.translate.instant('MAP.LOCATE_ME'),
          popup: this.translate.instant('MAP.YOUR_LOCATION'),
        },
        locationOptions: {
          maxZoom: 15,
        },
      };

      let locateControl = L.control.locate(locateOptions).addTo(map);

      this.translate
        .stream(['MAP.LOCATE_ME', 'MAP.YOUR_LOCATION'])
        .pipe(takeUntil(this.destroyed$))
        .subscribe((strings) => {
          locateControl.stop();
          locateControl.remove();
          locateOptions.strings.title = strings['MAP.LOCATE_ME'];
          locateOptions.strings.popup = strings['MAP.YOUR_LOCATION'];
          locateControl = L.control.locate(locateOptions).addTo(map);
        });
    }

    // Subscribe to saved locations
    this.mapdata.savedLocations.pipe(takeUntil(this.destroyed$)).subscribe((locations) => {
      this.savedLocations = locations;
      this.updateMap();
    });

    this.mapdata.refreshHoods();
    this.updateMap();

    this.map = map;
    this.mapReady.next(map);
    if (this.initLots) this.loadLots();

    // Add center markers incase map is already zoomed in
    if (this.map.getZoom() > 12) {
      const hasMarkerLayer = this.map.hasLayer(this.municipalityCenterMarkers);
      if (!hasMarkerLayer) {
        this.map.addLayer(this.municipalityCenterMarkers);
      }
    }
  }

  public openServiceFilter() {
    if (!this.pointMenuOpen) {
      this.pointMenuOpen = true;
      this.pointMenuEvent.next();

      if (this.servicePointComponent) {
        this.servicePointComponent.destroy();
      }

      this.servicePointComponent = this.resolver
        .resolveComponentFactory(ServicePointsSelector)
        .create(this.injector);

      if (this.appRef['attachView']) {
        this.appRef['attachView'](this.servicePointComponent.hostView);
        this.servicePointComponent.onDestroy(() => {
          this.appRef['detachView'](this.servicePointComponent.hostView);
        });
      } else {
        this.appRef['registerChangeDetector'](this.servicePointComponent.hostView);
        this.servicePointComponent.onDestroy(() => {
          this.appRef['unregisterChangeDetector'](this.servicePointComponent.hostView);
        });
      }

      this.servicePointComponent.instance.refreshPoints
        .pipe(takeUntil(this.destroyed$))
        .subscribe((filter) => {
          this.mapServicePoints(filter, { removeExistingPoints: true });
        });

      this.servicePointComponent.instance.close.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.pointMenuOpen = false;
        this.pointMenuEvent.next();
        this.body.removeChild(this.servicePointComponent.location.nativeElement);
      });

      this.servicePointComponent.instance.toggleLots
        .pipe(takeUntil(this.destroyed$))
        .subscribe((show) => {
          if (show) {
            this.map.addLayer(this.properties);
            this.servicePointComponent.instance.lotsShown = true;
            this.mapdata.savePreference('showLots', true);
          } else {
            this.map.removeLayer(this.properties);
            this.servicePointComponent.instance.lotsShown = false;
            this.mapdata.savePreference('showLots', false);
          }
        });

      this.servicePointComponent.instance.toggleHoods
        .pipe(takeUntil(this.destroyed$))
        .subscribe((show) => {
          if (show) {
            this.map.addLayer(this.hoods);
            this.servicePointComponent.instance.hoodsShown = true;
            this.mapdata.savePreference('showHoods', true);
          } else {
            this.map.removeLayer(this.hoods);
            this.servicePointComponent.instance.hoodsShown = false;
            this.mapdata.savePreference('showHoods', false);
          }
        });

      L.DomEvent.disableClickPropagation(this.servicePointComponent.location.nativeElement);

      this.setPopUpPosition();

      this.body.appendChild(this.servicePointComponent.location.nativeElement);
    } else {
      this.pointMenuOpen = false;
      this.pointMenuEvent.next();

      this.body.removeChild(this.servicePointComponent.location.nativeElement);
    }
  }
  showProperties() {
    if (!this.map.hasLayer(this.properties)) {
      this.map.addLayer(this.properties);
      this.servicePointComponent.instance.lotsShown = true;
      this.mapdata.savePreference('showLots', true);
    }
  }

  addPropertiesControl() {
    let propertiesControl = L.Control.extend({
      options: { position: 'topleft' },
      onAdd: (map) => {
        let container = L.DomUtil.create(
          'button',
          'leaflet-bar leaflet-control leaflet-control-property',
        );

        container.style.padding = '6px 8px';
        container.style.backgroundColor = 'white';

        let layer = this.properties;

        this.translate
          .stream('HIDE_PROPERTIES')
          .pipe(takeUntil(this.destroyed$))
          .subscribe((translate) => {
            container.innerHTML = translate;
          });

        container.onclick = () => {
          if (map.hasLayer(layer)) {
            map.removeLayer(layer);
            this.translate
              .stream('SHOW_PROPERTIES')
              .pipe(takeUntil(this.destroyed$))
              .subscribe((translate) => {
                container.innerHTML = translate;
              });
          } else {
            map.addLayer(layer);
            this.translate
              .stream('HIDE_PROPERTIES')
              .pipe(takeUntil(this.destroyed$))
              .subscribe((translate) => {
                container.innerHTML = translate;
              });
          }
        };
        return container;
      },
    });

    this.map.addControl(new propertiesControl());
  }

  addLayer(object) {
    let geoJson = geoJSON(<any>object);
    this.layers.push(<any>geoJson);
  }

  public async cancelLotsData() {
    this.lotsDataRequest.unsubscribe();
  }

  private loadLots() {
    const bbox = bboxString(this.map);
    if (this.lotsDataRequest && !this.lotsDataRequest.closed) {
      this.cancelLotsData();
    }

    this.lotsDataRequest = this.propertyService
      .getPropertiesBbox(bbox)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.updateLots(result);
      });
  }

  private async updateLots(lotsData) {
    let lots = lotsData.lots.sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      }
      if (a.type < b.type) {
        return -1;
      }

      if (a.address > b.address) {
        return 1;
      }
      if (a.address < b.address) {
        return -1;
      }
    });

    // Filter out properties that are not in eura and don't have area set
    lots = lots.filter(
      (x) =>
        !(
          !x.referenceId.startsWith(ReferenceIdPrefix.KarttatiimiEura) &&
          !x.referenceId.startsWith(ReferenceIdPrefix.SwecoPoytya) &&
          !x.area
        ),
    );

    const lotPics = await this.propertyService.getPictures(lots.map((l) => l.id)).toPromise();
    const lotPictures = {};
    for (const key in lotPics.lotPictures) {
      if (Object.prototype.hasOwnProperty.call(lotPics.lotPictures, key)) {
        const element = lotPics.lotPictures[key];
        lotPictures[element.lotId] = element;
      }
    }

    const muniIds: number[] = Array.from(new Set(lots.map((x) => x.municipalityId)));
    const municipalities: { [id: string]: any } = {};
    for (const id of muniIds) {
      const muniData = await this.municipalitiesService.getMunicipality(id).toPromise();
      municipalities[id] = muniData.municipality;
    }

    if (lots.length === 0) return;

    const urlParams = new URLSearchParams(window.location.search);
    let openToLotId: number | undefined;
    if (urlParams && urlParams.has('lotId')) {
      openToLotId = parseInt(urlParams.get('lotId'), 10);
    }
    let lotDialogItem: L.GeoJSON | L.Marker | undefined;

    lots = lots.filter((x) => !this.addedLots.has(x.id));
    for (const property of lots) {
      this.addedLots.add(property.id);
      const muni = municipalities[property.municipalityId];
      const image = lotPictures[property.id];
      const popupContent = await this.municipalitiesService.lotPopupText(
        property,
        muni.slug,
        property.hoods && property.hoods[0],
        this.translate.currentLang,
        undefined,
        image,
        muni.name,
      );

      let color = '';
      let icon = '';
      const icons = ['warehouse', 'home', 'building', 'briefcase', 'hotel'];

      if (property.type == 3) {
        color = 'blue'; // yritystontti + teollisuus
        icon = icons[3];
      } else if (property.type == 1) {
        color = 'red'; // omakotitontti
        icon = icons[1];
      } else {
        color = 'purple'; // rivi ja kerrostalo
        icon = icons[2];
      }

      const geojsonMarkerOptions = {
        icon: L.divIcon({
          html: this.addIcon(icon),
          className: 'lot-cluster single cat-' + property.id,
        }),
      };

      const onMarkerClick = async (e: L.LeafletEvent) => {
        if (
          lots.findIndex((lot) => {
            return lot.referenceId == property.referenceId;
          }) !== -1
        ) {
          console.log('popupopen');
          this.selectedLot = property;

          const data = {
            selectedLot: property,
            municipalitySlug: muni.slug,
            hoods: property.hoods && property.hoods[0],
            currentLanguage: this.translate.currentLang,
            image: image,
            municipalityName: muni.name,
          };

          // Show a fullscreen dialog for marker popups on mobile
          if (this.isMobileDevice()) {
            this.openDialog(data);
            return;
          }
        }

        let onclickGtag = '';
        let propertyType = '';

        if (property.type === 1) {
          propertyType = 'Omakotitalotontti';
        } else if (property.type === 2) {
          propertyType = 'Kerrostalotontti';
        } else if (property.type === 3) {
          propertyType = 'Teollisuustontti';
        } else if (property.type === 4) {
          propertyType = 'Vapaa-ajan tontti';
        } else if (property.type === 0) {
          propertyType = 'Muut tontit';
        }

        let municipality = municipalities[property.municipalityId];
        let municipalityName =
          municipality.name['fi'] || municipality.name[Object.keys(municipality.name)[0]];

        (<any>window).gtag('event', 'view_lot_details', {
          event_category: propertyType,
          event_label: municipalityName + ' - ' + property.referenceId + ' - ' + property.name,
        });

        onclickGtag =
          "onclick=\"window.gtag('event', 'open_reservation_service', {'event_category' : '" +
          propertyType +
          "','event_label' : '" +
          municipalityName +
          ' - ' +
          property.referenceId +
          ' - ' +
          property.name +
          '\'});"';

        const popupContent = await this.municipalitiesService.lotPopupText(
          property,
          muni.slug,
          property.hoods && property.hoods[0],
          this.translate.currentLang,
          onclickGtag,
          image,
          muni.name,
        );
        e.target.getPopup().setContent(popupContent);
      };

      const popupCloseFunc = async () => {
        await waitForMs(100);
        console.log('popupclose');
        this.selectedLot = undefined;
      };

      const propertyTranslation = this.propertyService.getTranslation(
        property,
        this.translate.currentLang,
      );
      let geojson: L.GeoJSON<any> | undefined;
      if (property.area) {
        geojson = L.geoJSON(property.area, {
          style: {
            color: color,
            fillColor: color,
            fillOpacity: 0.2,
          },
        })
          .bindTooltip(propertyTranslation.name, {
            direction: 'auto',
            offset: L.point(0, 0),
          })
          .bindPopup(popupContent, { className: 'lot-marker' })

          .on('popupopen', onMarkerClick)
          .on('popupclose', popupCloseFunc);
        geojson.addTo(this.properties);
      }
      if (property.point || geojson) {
        let latlng: L.LatLng;
        if (property.point) {
          const coords = property.point.geometry.coordinates;
          latlng = L.latLng([coords[1], coords[0]]);
        } else {
          latlng = geojson.getBounds().getCenter();
        }
        let point = L.marker(latlng, geojsonMarkerOptions)
          .bindTooltip(propertyTranslation.name, {
            direction: 'auto',
            offset: L.point(0, 0),
          })
          .bindPopup(popupContent, { className: 'lot-marker' })
          .on('popupopen', onMarkerClick)
          .on('popupclose', popupCloseFunc);
        point.addTo(this.lotMarkers);
        if (property.id === openToLotId) {
          lotDialogItem = point;
        }
      }
    }
    this.lotMarkers.addTo(this.properties);

    if (this.initLotsOpenDialog && !this.initLotsDialogOpened && lotDialogItem) {
      this.initLotsDialogOpened = true;

      let lot = lots.find((lot) => {
        return lot.id === openToLotId;
      });

      let geometry = lot.area || lot.point;

      let object = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: geometry.geometry,
          },
        ],
      };

      let geojson = L.geoJSON(<any>object);

      const bounds = geojson.getBounds();

      this.map.addOneTimeEventListener('moveend', async () => {
        // Sometimes not actually finished moving. Need small delay
        await waitForMs(100);
        if (!this.isMobileDevice()) {
          lotDialogItem.openPopup();
        }
      });

      this.map.fitBounds(PadNorth(bounds.pad(0.01), 2));
    }
  }

  private addIcon(name) {
    let icon = name;
    return '<i class="fas fa-' + icon + '"></i>';
  }

  async mapServicePoints(
    area: 'hood' | 'city',
    {
      refetchPoints,
      removeExistingPoints,
    }: { removeExistingPoints?: boolean; refetchPoints?: boolean },
  ) {
    let url = this.router.url.split('/');
    let urlQueryString = [];
    if (url.length > 2) {
      urlQueryString = url[2].split('?');
    }
    if (url.includes('register') || urlQueryString.includes('register')) {
      this.showServicePoints = false;
    }

    if (!this.showServicePoints) {
      console.log('Not showing service points.');
      return;
    }

    if (!this.map || !this.map.getBounds()) {
      // if we have no bounds for the map, we simply will not create points
      console.log('map has not been set to bounds yet');
      return;
    }

    const types = await this.mapdata.servicePointTypes$.pipe(take(1)).toPromise();

    const selectedTypes = types.filter((type) => type.selected).map((type) => type.id);
    if (!selectedTypes || selectedTypes.length === 0) {
      this.services.clearLayers();
      this.addedServicePoints = [];

      // no categories selected, so skip filtering. Nothing should be shown
      return;
    }

    if (!this.servicePoints || refetchPoints) {
      const bbox = bboxString(this.map);
      this.leafletMessageboxService.createControl(this.map);
      const loadingMessage = this.translate.instant('MAP.LOADING_SERVICE_POINTS');
      this.leafletMessageboxService.showMessage(
        `<i class="fa fas fa-icon fa-spinner spinner-fast"></i> ${loadingMessage}`,
        30000, // 30sec timeout just in case
      );

      try {
        this.servicePoints = await this.mapdata.getServicePoints(area, bbox, true);
      } finally {
        this.leafletMessageboxService.hideMessage();
      }
    }

    // TODO: Move this filtering to a separate function. Functions should be simpler than this one is.
    let mapPointsExist = false;
    if (removeExistingPoints) {
      this.services.clearLayers();
      this.addedServicePoints = [];
    }
    const filtered = this.servicePoints.filter((point) => {
      if (this.addedServicePoints.includes(point.id)) {
        return false;
      }
      const coords = point.c;
      if (!point.visible) {
        // point visibility disabled in database
        return false;
      }
      let inBounds = true;
      // if point out of bounds, never show it
      if (!this.map.getBounds().contains([coords[1], coords[0]])) {
        inBounds = false;
      }
      // if point has priority type, it should be shown if inside bounds, so check point types
      const priorityTypes = this.mapdata.getAlwaysSelectedTypes();
      const hasPriorityType = priorityTypes.some((t) => point.tids.includes(t));
      if (!point.showLocations && !hasPriorityType) {
        // municipality locations visibility disabled in database
        // omit if priorityType
        return false;
      }
      // check to see if one of the types of a point has been selected in the UI
      const hasSelectedType = selectedTypes.some((t) => point.tids.includes(t));
      if (hasSelectedType) {
        mapPointsExist = true;
      }
      if (!inBounds) {
        return false;
      }
      return hasSelectedType;
    });

    if (this.map.getZoom() < 7 && mapPointsExist) {
      // generate error message that is shown on bottom-left corner controls
      // if map zoom is less than 7 and map points in general exist somewhere in map
      this.leafletMessageboxService.createControl(this.map);
      const errorMessage = this.translate.instant('MAP.ZOOM_IN_ERROR');
      this.leafletMessageboxService.showMessage(errorMessage);
      return;
    }

    filtered.forEach((point) => {
      const categories = point.tids;
      const icon = categories.length === 1 ? categories[0] : 0;
      const geojsonMarkerOptions = {
        icon: L.divIcon({
          html: this.addIcon(this.serviceIcons[icon]),
          className: 'leaflet-div-icon single cat-' + icon,
          iconSize: [32, 32],
        }),
      };
      this.addServicePointMarkers(categories, point, geojsonMarkerOptions);
      this.addedServicePoints.push(point.id);
    });
  }

  private async addServicePointMarkers(
    locationTypeIds: number[],
    point: SimplifiedLocation,
    geojsonMarkerOptions: { icon: L.DivIcon },
  ) {
    let popupContent = '';
    let url = [];
    let showLink = true;
    let customIcon: string;
    let linkText = '';
    const coords = point.c;

    // TODO: all of this should be in a separate function
    if (
      locationTypeIds.includes(21) ||
      locationTypeIds.includes(22) ||
      locationTypeIds.includes(24)
    ) {
      // Campaign categories
      linkText = 'OPEN_CAMPAIGN';
      if (locationTypeIds.includes(21)) {
        customIcon = 'fa-spring';
      } else if (locationTypeIds.includes(22)) {
        customIcon = 'fa-event';
      } else if (locationTypeIds.includes(24)) {
        customIcon = 'fa-tree';
        linkText = 'POINT.LINK.SUMMER_CAMPAIGN';
      }

      if (point.url !== null) {
        url = point.url.split('|');

        if (url.length > 1) {
          if (url[0].split('/hood/')[1] === this.location.path().split('/hood/')[1]) {
            showLink = false;
          }
        } else {
          url[0] = url[0];
          url[1] = '';
        }
      } else {
        url[0] = '';
        url[1] = '';
      }
      L.marker(L.latLng([coords[1], coords[0]]), geojsonMarkerOptions)
        .addTo(this.services)
        .bindTooltip(point.name, {
          direction: 'top',
          offset: L.point(0, -15),
        })
        .bindPopup(popupContent, { className: 'campaign-marker', offset: new L.Point(1, -10) })
        .on('popupopen', async (e) => {
          // Set spinner while loading data
          const spinnerHtml = `<i class="fa fas fa-icon fa-spinner spinner-fast"></i>`;
          e.target.getPopup().setContent(spinnerHtml);

          const strings = await this.translate
            .get(['ADDRESS', 'OPEN_CAMPAIGN', 'GO_TO_HOOD', 'POINT.LINK.SUMMER_CAMPAIGN'])
            .toPromise();

          const { location } = await this.locationService.getLocation(point.id).toPromise();

          popupContent = `
              <div style="display:flex;align-items:center;">
                <fa-icon icon="home"></fa-icon>
                  <i class="fas ${customIcon}" style="font-size: 1.3em;margin-right: 0.8em;"></i>
                <div style="flex:1;"><strong>${point.name}</strong></div>
              </div>
              <hr>
              <strong>${strings['ADDRESS']}</strong>: ${location.address} ${
            location.city ? ', ' + location.city : ''
          }
              <br><br>
              ${
                showLink
                  ? `
                <a class="button" target="_blank" href="${url[0]}" style="display:block;width:100%;margin-bottom:1em;">${strings[linkText]}</a>`
                  : ''
              }
              `;
          // + '<a class="button" href="' + url[1] + '" style="display:block;width:100%;" target="_blank"><i class="fas fa-external-link-alt"></i> ' + strings['OPEN_CAMPAIGN'] + '</a>';
          e.target.getPopup().setContent(popupContent);
          this.map.closeTooltip(e.target.getTooltip());
        });
    } else {
      // Non-campaign categories
      L.marker(L.latLng([coords[1], coords[0]]), geojsonMarkerOptions)
        .addTo(this.services)
        .bindTooltip(point.name, {
          direction: 'top',
          offset: L.point(0, -15),
        })
        .bindPopup(popupContent, { className: 'service-marker', offset: new L.Point(4, -9) })
        .on('popupopen', async (e) => {
          //Get right icon from service category mapping
          const icon = locationTypeIds.length === 1 ? locationTypeIds[0] : 0;
          let iconString = this.serviceIcons[icon];

          const { location } = await this.locationService.getLocation(point.id).toPromise();

          // Show a fullscreen dialog for marker popups on mobile
          const data = {
            point: location,
            currentLanguage: this.translate.currentLang,
            icon: iconString,
          };
          if (this.isMobileDevice()) {
            this.openDialog(data);
          } else {
            popupContent = await this.locationService.locationPopupText(
              location,
              this.translate.currentLang,
              iconString,
            );
            // + '<a class="button" href="' + url[1] + '" style="display:block;width:100%;" target="_blank"><i class="fas fa-external-link-alt"></i> ' + strings['OPEN_CAMPAIGN'] + '</a>';
            e.target.getPopup().setContent(popupContent);

            this.map.closeTooltip(e.target.getTooltip());

            let feedbackBtn = document.querySelectorAll('.feedbackPopupBtn');
            feedbackBtn.forEach((btn) => {
              btn.addEventListener('click', this.toggleFeedbackPopup);
            });
            let closeBtn = document.querySelector('#closeFeedbackBtn');
            if (closeBtn) {
              closeBtn.addEventListener('click', this.toggleFeedbackPopup);
            }
          }
        });
    }
  }

  toggleFeedbackPopup(event) {
    event.stopPropagation();
    let feedbackBtn = document.querySelector('#feedbackPopup');
    feedbackBtn.classList.toggle('hide-modal');
  }

  isMobileDevice() {
    return /Mobi/i.test(navigator.userAgent);
  }

  closeAllPopups() {
    this.map.eachLayer((layer) => {
      if (layer.isPopupOpen()) {
        layer.closePopup();
      }
    });
  }

  openDialog(data: any) {
    this.closeAllPopups();

    const dialogRef = this.dialog.open(MapMarkerPopupComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: data,
    });

    // ngZone fixes duplicate dialog appearing
    this.ngZone.run(() => {
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((result) => {
          this.dialog.closeAll();
          // this.centerToLot(this.selectedLot.referenceId, true);
        });
    });
  }
}
