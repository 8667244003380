import { Translations } from '../modules/landing/home/home.component';
import { Municipality } from '../services/municipalities/municipalities.service';

// Update switch cases if language abbreviations are modified
export function getLocaleAbbreviation(currentLanguage: string): 'fi' | 'en' | 'sv' {
  switch (currentLanguage) {
    case 'fi':
      return 'fi';
    case 'en':
      return 'en';
    case 'sv':
      return 'sv';
    default:
      return 'fi';
  }
}

interface HasTitle {
  name: { [key: string]: string } | Translations;
}

export function sortMunicipalitiesWithHoodsAlphabetically<T extends HasTitle>(
  municipalities: T[],
  lang: string,
): T[] {
  if (!Array.isArray(municipalities) || municipalities.length === 0) return municipalities;

  let locale = getLocaleAbbreviation(lang);

  const arr = municipalities.slice();
  arr.sort((a, b) => {
    // Fallback to finnish name if locale name doesnt exist
    const aName = a.name[locale] || a.name['fi'];
    const bName = b.name[locale] || b.name['fi'];

    return aName.localeCompare(bName, locale);
  });
  return arr;
}
