import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

import { IVote } from '../../models/IVote';
import { SingleReviewRatingComponent } from '../../modules/hood/single-review-rating/single-review-rating.component';
import { VotingService } from '../../services/voting/voting.service';
import { HoodsService } from '../../services/hoods/hoods.service';
import { TranslateService } from '@ngx-translate/core';

import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
})
export class ReviewCardComponent implements OnInit {
  @Input() review;
  @Input() currentUserId;
  @Input() user;
  @Input() showEditButton;

  faHeartSolid = faHeartSolid;

  hoodSlug;
  currentLang = 'fi';

  constructor(
    private router: Router,
    private hoodsService: HoodsService,
    public dialog: MatDialog,
    private votingService: VotingService,
    public translate: TranslateService,
  ) {
    this.currentLang = translate.currentLang;
  }

  ngOnInit() {}

  openDialog(ratingsData) {
    const dialogRef = this.dialog.open(SingleReviewRatingComponent, {
      data: { ratings: ratingsData },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  calcUserAvg(ratings) {
    if (ratings !== undefined) {
      let result;
      let total = 0;
      let zero = 0;
      ratings.forEach((rating) => {
        total = total + rating.value;
        if (rating.value == 0) {
          zero++;
        }
      });
      result = total / (ratings.length - zero);
      return result;
    }
  }

  async editReview(hoodId, userId) {
    let hoodSlug = await this.getHoodDetails(hoodId);
    this.hoodSlug = hoodSlug;
    console.log(hoodSlug);
    this.router.navigate(
      [
        '/' +
          this.currentLang +
          '/hood/' +
          this.hoodSlug.municipality +
          '/' +
          this.hoodSlug.hood +
          '/leave-review',
      ],
      { queryParams: { id: userId } },
    );
  }

  async getHoodDetails(id) {
    return new Promise((resolve) => {
      this.hoodsService.getHood(id).subscribe((data) => {
        let hoodSlug = { hood: data['hood'].slug, municipality: data['hood'].municipality.slug };
        resolve(hoodSlug);
      });
    });
  }

  async voteReview(dir: number, id: number) {
    let ip = new Promise((resolve, reject) => {
      this.votingService.getIpAddress().subscribe((data) => {
        ip = data.headers['x-client-ip'];
        resolve(ip);
      });
    });

    let result = await ip;

    const payload: IVote = {
      objectType: 'review',
      objectId: id,
      ipAddress: result as string,
      likeStatus: dir,
    };

    this.votingService.updateVote(payload).subscribe((data: any) => {
      this.review.votes = data.votes[0];
    });
  }
}
