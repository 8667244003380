import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
})
export class ArticleCardComponent implements OnInit {
  @Input() article: any;

  currentLang = 'fi';

  constructor(public translate: TranslateService) {
    this.currentLang = this.translate.currentLang;

    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = this.translate.currentLang;
    });
  }

  ngOnInit() {
    let postTitle = this.getTranslation(this.article.post_title, this.currentLang);
    if (postTitle) {
      this.article.post_title = postTitle;
    } else {
      this.article.post_title = this.getTranslation(this.article.post_title, 'fi');
    }
  }

  getTranslation(content: any, args?: any): any {
    let cleanup = content.replace(/(\r\n|\n|\r)/gm, '');
    let regex = new RegExp('{{lang:' + args + '}}(.*){{/lang:' + args + '}}');
    let split = cleanup.split(regex);
    return split[1];
  }
}
