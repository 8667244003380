import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectfilter',
  pure: false,
})
export class ObjectFilter implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter((item) => {
      if (item !== null && item.cat == (<any>filter).id) {
        return true;
      } else false;
    });
  }
}
