import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { Location } from '../../../services/locations/locations.model';
import { SimpleMapService } from '../../simple-map/simple-map.service';
import { ServicesService } from '../services.service';
import { SimpleMapComponent } from '../../simple-map/simple-map.component';
import { ServicesListItemDetailsComponent } from '../services-list-item-details/services-list-item-details.component';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-services-list-item',
  templateUrl: './services-list-item.component.html',
  styleUrls: ['./services-list-item.component.scss'],
})
export class ServicesListItemComponent implements OnInit {
  @Input() service: Location;
  @Output() public isMapOpen = new EventEmitter<boolean>();

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
  }
  public isMobile = false;
  public categoryIcon: string;
  private multipleCategoryIcon = 'shapes';
  public tooltipTranslation: string;
  public disableGoToMapMarkerButton = true;

  constructor(
    public dialog: MatDialog,
    private simpleMapService: SimpleMapService,
    private servicesService: ServicesService,
    public tooltipConfig: NgbTooltipConfig,
    private snackBar: MatSnackBar,
  ) {
    this.getScreenSize();
    tooltipConfig.triggers = 'click hover';
  }

  ngOnInit() {
    this.setEnableGoToMapMarkerButtonState();
    this.getCategoryIcon();
    this.getTooltipTranslation();
  }

  async setEnableGoToMapMarkerButtonState() {
    if (!this.service.coordinates) {
      this.disableGoToMapMarkerButton = true;
      return;
    }
    // Disable icon button if point is outside of selected area but has coordinates.
    const isPointInPolygon = await this.isPointInPolygon();
    if (isPointInPolygon) {
      this.disableGoToMapMarkerButton = false;
    } else {
      this.disableGoToMapMarkerButton = true;
    }
  }

  async isPointInPolygon(): Promise<boolean> {
    const area = await this.servicesService.areaProperties$.pipe(take(1)).toPromise();
    const isPointInPolygon = this.simpleMapService.isPointInPolygon(
      [this.service.coordinates[0], this.service.coordinates[1]],
      area.area.geometry,
    );
    return isPointInPolygon;
  }

  getCategoryIcon() {
    if (this.service.categories.length === 1) {
      this.categoryIcon = this.service.categories[0].icon;
    } else {
      this.categoryIcon = this.multipleCategoryIcon;
    }
  }

  async getTooltipTranslation() {
    if (this.service.coordinates) {
      this.tooltipTranslation = 'services-list-item.tooltip.open-map';
      const isPointInPolygon = await this.isPointInPolygon();
      if (!isPointInPolygon) {
        this.tooltipTranslation = 'services-list-item.tooltip.label';
      }
    } else {
      this.tooltipTranslation = 'services-list-item.tooltip.label';
    }
  }

  openServiceItemDetails() {
    const options: MatDialogConfig = {
      panelClass: 'services-list-item-details-dialog',
      data: { id: this.service.id, referer: 'item' },
    };
    if (this.isMobile) {
      options.width = '100%';
      options.height = '100%';
      options.maxWidth = '100%';
      options.autoFocus = false;
    } else {
      options.width = '900px';
      options.height = '600px';
      // Stops autoscrolling the modal to focusable element
      options.autoFocus = false;
    }
    const dialogRef = this.dialog.open(ServicesListItemDetailsComponent, options);
    if (this.isMobile) {
      dialogRef.afterOpened().subscribe(() => {
        this.servicesService.setIsItemDetailsOpen(true);
      });
      dialogRef.afterClosed().subscribe(() => {
        this.servicesService.setIsItemDetailsOpen(false);
      });
    }
  }

  panToMapMarker(openMapDialog?: boolean) {
    if (openMapDialog) {
      if (!this.service.coordinates) {
        this.openSnackBar();
        return;
      }
      const dialogRef = this.dialog.open(SimpleMapComponent, {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        panelClass: 'simple-map-dialog',
        data: {
          zoomToMarkerId: this.service.id,
        },
      });
      dialogRef.afterOpened().subscribe(() => {
        this.servicesService.setIsMobileMapOpen(true);
      });
    } else {
      this.simpleMapService.emitZoomToMarker(this.service.id);
    }
  }

  openSnackBar() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: 'services-list-item.snackbar.label',
      duration: 4000,
      panelClass: 'service-list-item-snackbar',
    });
  }
}
